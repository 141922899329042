<template>
  <div class="" v-shilp-loader.overlay="isLoading">
    <header class="content-header">
      <h3 class="content-title-blue">Brands</h3>
    </header>
    <!-- <div>
      <
      <main>
        <div class="brands-wrap">
          <div
            class="brands"
            v-for="(item, index) in brands"
            :key="'brand' + index"
            @click="viewProductsByBrand(item.id, item.name)"
          >
            <img
              v-if="item.logo && item.logo.url"
              :src="item.logo.url"
              alt="Brand"
            />
            <h4 class="brands__text bi-capitalize" v-if="item && item.name">
              {{ item.name }}
            </h4>
          </div>
        </div>
      </main>
    </div> -->
    <ListEmpty title="No Brands Found" content="" v-if="!brands.length"/>
    <ul class="brands-wrap" v-else>
      <li class="brands" v-for="(item, index) in brands" :key="index" @click="viewProductsByBrand(item.id, item.name, item.productCount)">
        <div class="brands-container">
          <div class="bi-pb-2 bi-text-center bi-h-8">
            <Tag v-if="item.tag" :label="item.tag" />
          </div>
          <div class="logo-card">
            <img v-if="item.logo && item.logo.url" :src="item.logo.url" alt="Brand" />
          </div>
        </div>

        <h4 class="bi-mt-3 bi-block bi-text-center bi-capitalize bi-font-bold bi-w-full" v-if="item && item.name">
          {{ item.name }}
        </h4>
      </li>

      <li v-if="brands.length < this.totalCount" class="brands">
        <div class="bi-justify-center">
          <s-button label="Show More" theme="outline" color="secondary" rounded size="lg" @click.native="loadMoreBrands" />
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { get } from "@/api/brands";
import { inquiryType } from "@/data/index"
import kycMixin from "@/mixins/kycMixin";
import { mapGetters } from "vuex";
export default {
  inquiryType,
  mixins: [kycMixin],
  components: {
    Tag: require("@/components/Tag").default,
    ListEmpty: require("@/components/ListEmpty").default,
  },
  data() {
    return {
      brands: [],
      isLoading: false,
      totalCount: 0,
      limit: 6,
      page: 1,
      projectId: null
    };
  },
  props: {
    categoryId: {
      type: [String, Number],
      default: null,
    },
    categoryName: {
      type: String,
      default: null
    },
  },
  computed: {
    ...mapGetters({
      // projectId: "user/getProjectId",
      user: "user/get",
    }),
  },
  mounted() {
    this.getBrands();
  },
  watch: {
    // this watch is used here because $root event was instantly getting called with old value of CategoryId instead of newValue.
    categoryId() {
      this.resetState();
      this.getBrands();
    },
    projectId() {
      this.getBrands();
    },
  },
  methods: {
    resetState() {
      this.brands = [];
      this.totalCount = 0;
      this.page = 1;
    },
    loadMoreBrands() {
      if (this.brands.length < this.totalCount) {
        this.page++;
        this.getBrands(true);
      }
    },
    viewProductsByBrand(brandId, brandName,product) {      
      if (this.isKycApproved()) {
        // this.$router.push({
        //   name: "product-index",
        //   params: {
        //     brandId: brandId,
        //     categoryId: this.categoryId,
        //     brandName: brandName,
        //   },
        // });
        if (product) {
          this.$router.push({
          name: "product-list-by-brand",
          params: {
            id: brandId,
            categoryId: this.categoryId,
            brandName: brandName,
          },
        });
        } else {          
         this.$router.push({
            name: 'inquiry-create',
            params: { category: this.categoryName, brand: brandName, fieldsDisabled: false, inquiryType: inquiryType.requestForPrice },
          })
        }


      } else {
        this.showKycStatusNotification();
      }
    },
    async getBrands(loadMore = false) {
      const defaultProject = this.user.companies[0].projects.find(project => project.isDefault === true);
        if (defaultProject) {
          this.projectId = defaultProject.id;
        }
      if (this.projectId) {
        try {
      this.isLoading = true;
          const res = await get({
            limit: this.limit,
            page: this.page,
            filter: { category_id: this.categoryId },
            project_id: this.projectId,
            sort: {
              by: "sort_order",
              order: "asc",
            },
          });

          if (!loadMore) this.brands = [];
          this.brands.push(...res.data);
          if(this.brands.length){
          this.$emit("total-count", this.brands[0].productCount)
          }
          this.totalCount = res.meta.total;
        } catch {
          this.$shilp.notify({
            title: "Something went wrong while getting Brands.",
            type: "danger",
          });
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
