<template>
  <div class="bi-bg-secondary-default bi-text-white bi-text-center bi-p-2 bi-text-2xl banner">
    You are inside <strong>{{ companyName }}</strong> Company
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      companyName: "user/companyName",
    }),
  },
};
</script>

<style lang="scss" scoped>
.banner{
  z-index: 70;
}
</style>
