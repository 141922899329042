<template>
  <div class="bi-p-4">
    <ProjectDropDownWrapper />
    <portal to="header-actions">
      <div class="header-actions">
        <s-button shape="circle" theme="link" color="grey" icon="IconMdiFilterOutline" @click.native="isFilterBarVisible = !isFilterBarVisible" />
        <div class="bi-hidden md:bi-block">
          <SearchFilter @search="searchQuery = $event" />
        </div>
        <!-- TODO Implement Export Order Feature -->
      </div>
    </portal>

    <FilterBar :visible="isFilterBarVisible" @reset-filters="resetFilters" @filter-bar-close="isFilterBarVisible = !isFilterBarVisible">
      <template #default>
        <!-- Invoice Status Option -->
        <div class="bi-block md:bi-hidden bi-my-2">
          <h5 class="text-grey bi-my-2">Search Filter</h5>
          <SearchFilter @search="searchQuery = $event" />
        </div>
        <div class="bi-my-2">
          <h5 class="text-grey bi-my-2">Invoice Status</h5>
          <InvoiceStatusDropdown v-model="filters.invoice_empty" />
        </div>

        <s-field label="Category">
          <CustomDropDown
            :options="categories"
            v-model="filters.category_id"
            placeholder="Select"
            :clearable="true"
            @clear-selection="filters.category_id = null"
          />
        </s-field>
        <h5 class="text-grey bi-my-2">Date</h5>
        <CustomDatePicker v-model="filters.date_range" />

        <h5 class="text-grey bi-my-2">Brands</h5>
        <div class="bi-flex flex--fit bi-gap-2 bi-flex-wrap" v-shilp-loader.overlay="isBrandsLoading">
          <s-button
            v-for="(brand, index) in brands"
            :key="'brand' + index"
            class="bi-py-4 filter font-semibold"
            :active="brandFilters.includes(brand.id)"
            :label="brand.name"
            theme="muted"
            @click.native="applyBrandFilter(brand.id)"
            color="grey"
          />
        </div>

        <!-- Payment Status  -->
        <h5 class="text-grey my-2">Payment Status</h5>
        <div class="bi-flex flex--fit bi-gap-2 bi-flex-wrap">
          <s-button
            v-for="(payment, index) in paymentStatusOptions"
            :key="'paymentStatus' + index"
            class="bi-py-4 filter bi-font-semibold"
            :active="paymentStatusFilter.includes(payment.id)"
            :label="payment.display_name"
            theme="muted"
            @click.native="applyPaymentFilter(payment.id)"
            color="grey"
          />
        </div>
      </template>
    </FilterBar>

    <template v-if="isOrderListLoading">
      <shimmer />
    </template>
    <template v-else>
      <template v-if="orderList.length">
        <div class="bi-overflow-x-scroll">
          <div style="min-width: 900px">
            <CustomInvoiceListHeading class="bi-p-2 bi-grid bi-grid-cols-8" />
            <div class="" v-for="(order, index) in orderList" :key="'orderId' + index">
              <!-- Order Heading -->
              <div class="bi-flex bi-items-center bi-mt-0 bi-border-b-2 bi-border-grey-lightest-ultra">
                <!-- Order ID and date -->
                <OrderBar
                  :order="order"
                  :showOrderStatus="false"
                  :showAction="false"
                  class="bi-flex-grow bi-p-2 bi-grid bi-grid-cols-8 bi-justify-between bi-items-center hover:bi-bg-grey-lightest-double-ultra"
                  @click.native="toggleProductDisplay(order.id, index, order)"
                  :class="{ 'bi-cursor-pointer': order.invoices.length }"
                />
                <!-- :class="{ 'clickable-item': order.invoices.length }" -->
              </div>

              <!-- Order Products Table  -->

              <div :ref="`order-product-table-${order.id}`" class="bi-hidden">
                <div
                  class="bi-text-left invoice-list-grid bi-p-2 bi-text-xs bi-text-grey-default bi-font-normal bb-lightest bi-bg-grey-lightest-double-ultra"
                >
                  <h6 class="bi-text-grey-default bi-font-normal bi-my-2">Invoice List</h6>
                  <h6 class="bi-text-grey-default bi-font-normal bi-my-2 bi-text-right mr-6">Actions</h6>
                </div>
                <sp-list ref="list" class="h-100 order-list" :data="[order]" :actions="['']">
                  <template #empty>
                    <no-order-found />
                  </template>
                  <template #default="{ items }">
                    <template v-if="items[0].invoices.length">
                      <div v-for="(invoice, index) in items[0].invoices" :key="`invoice-item-container-${index}`">
                        <div class="bi-bg-grey-lightest-double-ultra bi-flex bi-justify-between bi-items-center bi-py-2 bb-lightest">
                          <div class="bi-ml-2">{{ invoice.invoice_media[0].filename }}</div>
                          <s-button type="submit" color="secondary" class="mr-5" @click.native="getInvoice(invoice.invoice_media[0].url)"
                            >Open</s-button
                          >
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="bi-text-center bi-my-6">
                        <span class="block bi-text-lg bi-font-bold">No Invoice Found! </span>
                      </div>
                    </template>
                  </template>
                </sp-list>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <list-empty :showClearFiltersButton="true" @resetFilters="resetFilters" class="bi-mt-16" />
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getBrands } from "@/utils/common";
import { getAll } from "@/api/order";
import { getPaymentStatusOptions, getOrderStatusOptions } from "@/api/options";
import kycMixin from "@/mixins/kycMixin";
import cartMixin from "@/mixins/cartMixin";
import CustomDatePicker from "@/components/CustomDatePicker.vue";

export default {
  mixins: [kycMixin, cartMixin],
  components: {
    OrderBar: require("@/components/order/Bar").default,
    NoOrderFound: require("@/components/NoOrderFound").default,
    ProjectDropDownWrapper: require("@/components/projectDropDown/Wrapper").default,
    Shimmer: require("@/components/order/Shimmer").default,
    ListEmpty: require("@/components/ListEmpty").default,
    CustomDropDown: require("@/components/CustomDropDown").default,
    CustomInvoiceListHeading: require("@/components/order/CustomInvoiceListHeading").default,
    FilterBar: require("@/components/order/FilterBar").default,
    InvoiceStatusDropdown: require("@/components/invoice/InvoiceStatusDropdown").default,
    SearchFilter: require("@/components/SearchFilter").default,
    CustomDatePicker,
  },
  created() {
    this.getAllFilterOptions();
    if (this.projectId) this.filters.project_id = this.projectId;
    this.get();
  },

  mounted() {},
  data() {
    return {
      isLoading: false,
      brandFilters: [],
      isBrandsLoading: false,
      searchQuery: "",
      paymentStatusFilter: [],
      paymentStatusOptions: [],
      orderList: [],
      isOrderListLoading: false,
      isFilterBarVisible: false,
      created_at: null,
      brands: [],
      filters: {
        brand_id: null,
        date_range: null,
        payment_status_id: null,
        order_status_id: [1, 5, 4, 10, 11, 2, 3],
        project_id: null,
        category_id: null,
        invoice_empty: null,
      },
      filterDate: null,
    };
  },
  watch: {
    searchQuery() {
      this.get();
    },
    projectId(newValue) {
      this.filters.project_id = newValue;
    },
    filters: {
      immediate: false,
      deep: true,
      handler: function () {
        this.get();
      },
    },
    "filters.category_id": {
      handler: function () {
        this.getAllBrands();
      },
    },
  },
  computed: {
    ...mapGetters({
      projectId: "user/getProjectId",
      categories: "categories",
    }),
  },
  methods: {
    ...mapActions(["addToCart", "updateCart"]),

    getInvoice(url) {
      window.open(url, "_blank");
    },

    toggleProductDisplay(orderId, _, order) {
      if (order.invoices.length) {
        const toggleClass = this.$refs[`order-product-table-${orderId}`][0].classList;

        if (toggleClass.contains("bi-hidden")) {
          toggleClass.remove("bi-hidden");
          toggleClass.add("bi-block");
        } else {
          toggleClass.remove("bi-block");
          toggleClass.add("bi-hidden");
        }
      }
    },

    async get() {
      this.isOrderListLoading = true;

      try {
        const res = await getAll({
          search: this.searchQuery,
          filter: {
            ...this.filters,
          },
          limit: 25,
          include: "products,products.brand,products.categories,products.attributeOptions.attribute,paymentStatus,invoice",
        });
        this.orderList = res.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isOrderListLoading = false;
      }
    },

    resetFilters() {
      this.brandFilters = [];
      this.paymentStatusFilter = [];
      this.searchQuery = "";
      this.filters = {
        brand_id: null,
        date_range: null,
        payment_status_id: null,
        project_id: this.projectId,
        order_status_id: [1, 5, 4, 10, 11, 2, 3],
        category_id: null,
        invoice_empty: null,
      };
    },
    applyPaymentFilter(id) {
      if (this.paymentStatusFilter.includes(id)) {
        this.paymentStatusFilter = this.paymentStatusFilter.filter((item) => {
          return id !== item;
        });
      } else {
        this.paymentStatusFilter.push(id);
      }

      this.filters.payment_status_id = this.paymentStatusFilter;
    },
    applyBrandFilter(id) {
      if (this.brandFilters.includes(id)) {
        this.brandFilters = this.brandFilters.filter((item) => {
          return id !== item;
        });
      } else {
        this.brandFilters.push(id);
      }

      this.filters.brand_id = this.brandFilters;
    },

    async getAllBrands() {
      this.isBrandsLoading = true;
      const categoryId = this.filters.category_id;
      if (categoryId && this.projectId) this.brands = await getBrands(categoryId, this.projectId);
      this.isBrandsLoading = false;
    },
    async getAllFilterOptions() {
      this.getAllBrands();

      this.paymentStatusOptions = await getPaymentStatusOptions();
    },
    exportOrders() {},
  },
};
</script>

<style scoped>
.header-actions {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.sp-table {
  padding: 0px;
}

.filter.button {
  background: var(--color--grey--lightest-double-ultra) !important;
}

.filter.button--active {
  background: var(--color--secondary--lightest-ultra) !important;
  @apply bi-text-secondary-darker hover:bi-text-secondary-default hover:bi-bg-secondary-lighter;
}
</style>
