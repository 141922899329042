<template>
  <ProductPrice :price="updatedPriceWithGst" :unit="unit" />
</template>

<script>
import { getAacBlocksGstPrice } from "@/utils/common.js";
export default {
  components: {
    ProductPrice: require("@/components/ProductPrice").default,
  },
  computed: {
    updatedPriceWithGst() {
      return this.price + getAacBlocksGstPrice(this.price, this.gstPercentage);
    },
  },
  props: {
    price: Number,
    unit: String,
    gstPercentage: Number,
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped></style>
