<template>
  <sign action="forget-password" class="forget-password">
    <div class="">
      <!-- CODE SENT -->
      <item-form
        :save="reset"
        width="125"
        :form-state.sync="formState"
        ref="form"
        :fields="resetFields"
        title="Create New Password"
        :notify="false"
        :gap="2"
      >
        <template #default="{ form }">
          <Username v-if="!gstNo && !codeSent" v-model="username" desc="We'll send you an OTP on your registered email and mobile." />
          <template v-if="codeSent">
            <div class="bi-flex bi-gap-2">
              <!-- <h3 class="bi-font-medium bi-text-lg bi-text-primary-default">Create New Password</h3> -->
              <div class="flex-center">
                <h3 class="bi-text-grey-default bi-text-sm bi-font-semibold">Username:</h3>
                <span class="bi-text-grey-darkest-ultra bi-ml-2 bi-font-semibold">{{ username || gstNo }}</span>
              </div>
            </div>

            <s-field-validate vid="newPassword" name="New Password" rules="required|min:8" :password-toggle="true">
              <s-textbox type="password" v-model="form.password" placeholder="New Password" />
            </s-field-validate>
            <s-field-validate vid="confirmPassword" name="Confirm Password" rules="required|password:@newPassword" :password-toggle="true">
              <s-textbox type="password" v-model="form.passwordConfirmation" placeholder="Confirm Password" />
            </s-field-validate>
          </template>
          <ValidationProvider rules="required">
            <otp-handler @mounted="onOtpHandlerMounted" v-model="form.code" :get="getOtp" :showSendToPartnerField="true" ref="otpHandler" />
          </ValidationProvider>
        </template>

        <template #footer="{ saving, validate }">
          <s-button
            :disabled="saving || !codeSent || validate.invalid"
            color="secondary"
            size="lg"
            label="Create Password"
            fluid
            :loader="saving"
            type="submit"
          />
        </template>
      </item-form>

      <s-button class="bi-font-medium bi-font-base bi-mt-4" :to="{ name: 'login' }" label="Back to Login" theme="link" color="secondary"></s-button>
    </div>
  </sign>
</template>

<script>
import forgetPassword from "@/graph/forgetPassword.gql";
import resetPassword from "@/graph/resetPassword.gql";

export default {
  components: {
    Sign: require("@/components/Sign").default,
    OtpHandler: require("@/components/OtpHandler").default,
    Username: require("@/components/Username").default,
  },
  data() {
    return {
      isResend: false,
      formState: {},
      codeSent: false,
      username: null,
      gstNo: undefined,
      resetFields: ["code", "password", "passwordConfirmation"],
    };
  },

  created() {
    if (this.$route.query.gst_no) {
      this.$shilp.notify({
        title: "You are logging in for the first time. Please set your password.",
        type: "success",
      });

      this.gstNo = this.$route.query.gst_no;
    }
  },

  methods: {
    onOtpHandlerMounted() {
      if (this.$route.query.gst_no) {

        this.$refs.otpHandler.getOtp();
      }
    },
    getOtp(params) {
      // Params contains { defaultPartnerOtpSend value }

      if (this.username || this.gstNo) {
        return this.$graphPublic(forgetPassword, {
          input: {
            username: this.username,
            ...params,
          },
        }).then((res) => {
          this.codeSent = true;
          // below reset is written to solve issue of auto triggering confirm password validation field
          // this.$refs.form.reset();
          return res.forgetPassword;
        });
      } else {
        this.$shilp.notify({
          title: "Please fill required fields",
          type: "danger",
        });
        return Promise.reject(false);
      }
    },

    reset(id, data) {
      data["code"] = Number(data.code);
      return this.$graphPublic(resetPassword, {
        input: { ...data,
          username: this.username,
         },
      }).then(() => {
        this.$router.push({
          name: "login",
        });
        this.$shilp.notify({
          title: "Password Changed",
          message: "Now you can login with your new password.",
          duration: 5000,
          type: "accent",
        });
      });
    },
  },
};
</script>

<style></style>
