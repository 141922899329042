<template>
  <div class="bi-w-full bi-overflow-visible">
    <ProjectDropDownWrapper />
    <div v-if="!productCount" class="bg-vepaar-primary-lightest bi-p-4 bi-flex bi-justify-between bi-items-center bi-flex-col md:bi-flex-row">
      <p class="bi-text-grey-default bi-text-md md:bi-text-xl bi-mx-auto bi-mb-3 md:bi-mb-0">
        We will start online ordering soon for this category. Meanwhile, please click on <b>Request for price</b> and fill all necessary details. Our
        team member will contact you as soon possible to help you with offline order.
      </p>
      <s-button
        label="Request For Price"
        @click.native="
          $router.push({ name: 'inquiry-create', params: { category: categoryName, inquiryType: $options.inquiryType.requestForPrice } })
        "
        color="info"
        size="lg"
      />
    </div>
    <order-history v-if="isKycApproved()" />
    <brands :categoryId="categoryId" :categoryName="categoryName" @total-count="productCount = $event"/>
    <attributes :categoryId="categoryId" />
  </div>

  <!-- <div class="p-8">
      <s-button
        v-if="isKycApproved()"
        label="View All"
        color="secondary"
        size="lg" 
        @click.native="
          $router.push({
            name: 'product-index',
            params: { categoryId: categoryId },
          })
        "
      />
    </div> -->
</template>

<script>
import kycMixin from "@/mixins/kycMixin";
import { inquiryType } from "@/data/index";

export default {
  inquiryType,
  props: ["categoryId","categoryName"],
  mixins: [kycMixin],
 
  components: {
    Brands: require("@/components/Brands").default,
    Attributes: require("@/components/Attributes").default,
    OrderHistory: require("@/components/OrderHistory").default,
    ProjectDropDownWrapper: require("@/components/projectDropDown/Wrapper")
      .default,
  },

  data() {
    return {
      productCount:null,
    };
  },
};
</script>

<style lang="scss">
.layout--top .layout__content {
  grid-row-start: 2;
}
// <style lang="scss">
// // .layout--top .layout__content {
// //   grid-row-start: 1;
// // }
//
</style>
