<template>
  <modal size="sm" title="Log Out" id="log-out-account-modal">
    <!-- Title comes here  -->
    <template #message>
      <p class="bi-text-sm bi-text-grey-default bi-font-display bi-font-normal">Are you sure, you want to log out ?</p>
    </template>
    <div class="bi-mt-8 bi-flex bi-flex-nowrap">
      <s-button
        label="Cancel"
        color="transparent"
        class="bi-flex-auto"
        size="lg"
        align="center"
        theme="outline"
        @click.native="$shilp.modal.close('log-out-account-modal')"
      />
      <s-button
        align="center"
        color="danger"
        class="bi-ml-2 bi-flex-auto bi-font-bold"
        size="lg"
        label="Logout"
        theme="muted"
        type="submit"
        @click.native="logout"
        :loader="loggingout"
      />
    </div>
  </modal>
</template>

<script>
import logout from "@/graph/logout.gql";
import { resetStore } from "@/store/index";
import { mapActions } from "vuex";
import { resetAuthStorage } from "../../utils/resetStorage.js";
import { getDeviceId } from "@/utils/common.js";

export default {
  data() {
    return {
      loggingout: false,
    };
  },
  methods: {
    ...mapActions(["resetCart"]),
    async logout() {
      this.loggingout = true;
      this.$graph(logout, {
        deviceId: await getDeviceId(),
      })
        .then(() => {
          resetAuthStorage();
          this.$shilp.modal.close("log-out-account-modal");
          this.$router.push({ name: "login" });

          resetStore();

          this.resetCart();
        })
        .finally(() => {
          this.loggingout = false;
        });
    },
  },
};
</script>

<style></style>
