<template>
  <div class="bi-p-4 bi-overflow-hidden">
    <ProjectDropDownWrapper />
    <portal to="header-actions">
      <div class="header-actions">
        <div class="bi-hidden sm:bi-flex sm:bi-items-center">
          <s-button
            v-if="orderList && orderList.length"
            icon="IconIcExcel"
            class="mr-2"
            color="secondary"
            label="Export"
            :loader="isLoading"
            v-tooltip="'Download data'"
            @click.native="downloadList"
          />
        </div>
        <SearchFilter @search="searchQuery = $event" class="bi-hidden md:bi-block" />
        <s-button shape="circle" theme="link" color="grey" icon="IconMdiFilterOutline" @click.native="isFilterBarVisible = !isFilterBarVisible" />
        <!-- TODO Implement Export Order Feature -->
        <div class="bi-flex sm:bi-hidden">
          <v-popover>
            <template #popover>
              <div class="bi-flex bi-items-center">
                <!-- <s-button
                  v-if="orderList && orderList.length"
                  class="mr-2"
                  color="secondary"
                  label="Show All"
                  :loader="isLoading"
                  @click.native="allOrder"
                /> -->
                <s-button
                  v-if="orderList && orderList.length"
                  icon="IconIcExcel"
                  class="mr-2"
                  color="secondary"
                  label="Export"
                  :loader="isLoading"
                  v-tooltip="'Download data'"
                  @click.native="downloadList"
                />
              </div>
            </template>
            <s-button icon="IconMdiMenu" size="lg" color="grey" theme="link" class="bi-mt-1" />
          </v-popover>
        </div>
      </div>
    </portal>

    <portal to="header-actions-leftside">
      <!-- <div class="bi-hidden sm:bi-block">
        <s-button class="ml-2" color="secondary" theme="link" label="Show All" @click.native="allOrder" />
      </div> -->
    </portal>

    <FilterBar :visible="isFilterBarVisible" @reset-filters="resetFilters" @filter-bar-close="isFilterBarVisible = !isFilterBarVisible">
      <template #default>
        <div class="bi-block md:bi-hidden bi-my-2">
          <h5 class="text-grey bi-my-2">Search Filter</h5>
          <SearchFilter @search="searchQuery = $event" />
        </div>
        <s-field label="Category">
          <CustomDropDown
            :options="categories"
            v-model="filters.category_id"
            placeholder="Select"
            :clearable="true"
            @clear-selection="filters.category_id = null"
          />
        </s-field>
        <h5 class="text-grey bi-my-2">Date</h5>
        <CustomDatePicker v-model="filters.date_range" />

        <h5 class="text-grey bi-my-2">Brands</h5>
        <div class="bi-flex flex--fit bi-gap-2 bi-flex-wrap" v-shilp-loader.overlay="isBrandsLoading">
          <s-button
            v-for="(brand, index) in brands"
            :key="'brand' + index"
            class="bi-py-4 filter font-semibold"
            :active="brandFilters.includes(brand.id)"
            :label="brand.name"
            theme="muted"
            @click.native="applyBrandFilter(brand.id)"
            color="grey"
          />
        </div>
        <!-- Order Status  -->
        <h5 class="text-grey bi-my-2">Order Status</h5>
        <div class="bi-flex flex--fit bi-gap-2 bi-flex-wrap">
          <s-button
            v-for="(order, index) in orderStatusOptions"
            :key="'orderStatus' + index"
            class="py-3 filter font-semibold"
            :active="orderStatusFilter.includes(order.id)"
            :label="order.display_name"
            theme="muted"
            @click.native="applyOrderFilter(order.id)"
            color="grey"
          />
        </div>

        <!-- Payment Status  -->
        <h5 class="text-grey my-2">Payment Status</h5>
        <div class="bi-flex flex--fit bi-gap-2 bi-flex-wrap">
          <s-button
            v-for="(payment, index) in paymentStatusOptions"
            :key="'paymentStatus' + index"
            class="bi-py-4 filter bi-font-semibold"
            :active="paymentStatusFilter.includes(payment.id)"
            :label="payment.display_name"
            theme="muted"
            @click.native="applyPaymentFilter(payment.id)"
            color="grey"
          />
        </div>

        <!-- Invoice Status Option -->
        <h5 class="text-grey my-2">Invoice Status</h5>
        <InvoiceStatusDropdown v-model="filters.invoice_empty" />
      </template>
    </FilterBar>

    <template v-if="isOrderListLoading">
      <shimmer />
    </template>
    <template v-else>
      <!-- v-if="totalOrder" -->
      <div class="bi-flex bi-mb-2">
        <!-- <div class="bi-py-2 bi-px-3 bi-bg-secondary-default bi-rounded-lg bi-shadow-lg bi-text-white">
            <p class="bi-font-bold">
              Total Orders : <span>{{ totalOrder }}</span>
            </p>
          </div> -->

        <div class="bi-flex flex--fit bi-gap-2 bi-flex-wrap">
          <!-- class="py-3 filter font-semibold" -->
          <!-- :active="orderStatusFilter.includes()" -->

          <s-button
            key="all_orders_filter"
            label="All"
            class="filter_tab"
            :active="filters.order_status_id == null && filters.vendor_order_assign !== 1"
            theme="muted"
            color="grey"
            @click.native="allOrders()"
          >
            <template #after>
              <span class="bi-ml-2">({{ allCount }})</span>
            </template>
          </s-button>

          <s-button
            key="active_orders_filter"
            label="Active"
            @click.native="applyOrderStatusTabFilter($options.orderStatusData.under_process)"
            theme="muted"
            color="grey"
            class="filter_tab"
            :active="filters.order_status_id === $options.orderStatusData.under_process"
          >
            <template #after>
              <div class="bi-ml-2 bi-flex bi-items-center bi-gap-2">
                <span>({{ activeCount }})</span>

                <InformationButton
                  color="grey"
                  v-tooltip="'Active orders include Status ( Requested, OnHold, Confirmed, Partial Shipped, Partial Delivered, Shipped )'"
                />
              </div>
            </template>
          </s-button>

          <s-button
            key="delivered_orders_filter"
            label="Delivered"
            theme="muted"
            color="grey"
            class="filter_tab"
            :active="filters.order_status_id === $options.orderStatusData.delivered"
            @click.native="applyOrderStatusTabFilter($options.orderStatusData.delivered)"
          >
            <template #after>
              <span class="bi-ml-2">({{ deliveredCount }})</span>
            </template>
          </s-button>

          <s-button
            key="requested_orders_filter"
            label="Action required"
            theme="muted"
            color="grey"
            class="filter_tab"
            :active="filters.vendor_order_assign === 1"
            @click.native="applyOrderConfirmationFilter(1)"
          >
            <template #after>
              <span class="bi-ml-2">({{ requestedCount }})</span>
            </template>
          </s-button>

          <!-- <s-button
              v-for="(order, index) in orderStatusOptions"
              :key="'orderStatus' + index"
              class="py-3 filter font-semibold"
              :active="orderStatusFilter.includes(order.id)"
              :label="order.display_name"
              theme="muted"
              @click.native="applyOrderFilter(order.id)"
              color="grey"
            /> -->
        </div>
      </div>
      <template v-if="orderList.length">
        <div class="bi-overflow-x-scroll">
          <div style="min-width: 1200px">
            <CustomOrderListHeading class="bi-p-2 order-list-grid" />
            <div v-for="(order, index) in orderList" :key="'orderId' + index">
              <!-- Order Heading -->
              <div class="bi-flex bi-items-center bi-mt-0 bi-border-b-2 bi-border-grey-lightest-ultra">
                <!-- Order ID and date -->
                <OrderBar :order="order" class="bi-flex-grow clickable-item bi-p-2 order-list-grid" @click.native="toggleProductDisplay(order.id)" />
              </div>

              <!-- Order Products Table  -->

              <div :ref="`order-product-table-${order.id}`" class="bi-hidden">
                <CustomProductListHeading class="bi-text-left product-list-grid" />
                <sp-list ref="list" :attrs="columns" class="h-100 order-list" :data="[order]" :actions="['']">
                  <template #empty>
                    <no-order-found />
                  </template>
                  <template #default="{ items }">
                    <div v-for="(product, index) in items[0].products" :key="`product-item-container-${index}`">
                      <div class="bi-bg-grey-lightest-double-ultra">
                        <!-- TODO Improvment Needed from Backend.Ask backend to provide proper order and payment status in particular product array instead of outside products array -->
                        <product-card
                          :product="product"
                          class="border-b border-grey-lightest-ultra product-list-grid"
                          :orderStatus="items[0].orderProducts[index].orderProductStatus"
                          :paymentStatus="items[0].orderProducts[index].orderProductPaymentStatus"
                        />
                      </div>
                    </div>
                  </template>
                </sp-list>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <list-empty :showClearFiltersButton="true" @resetFilters="resetFilters" class="bi-mt-16" />
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getBrands } from "@/utils/common";
import { getAll, downloadHistory, getOrderCount } from "@/api/order";
import { getPaymentStatusOptions, getOrderStatusOptions } from "@/api/options";
import kycMixin from "@/mixins/kycMixin";
import cartMixin from "@/mixins/cartMixin";
import orderStatusData from "@/data/orderStatus.js";
import CustomDatePicker from "@/components/CustomDatePicker.vue";
import InformationButton from "../../components/InformationButton.vue";
import { cloneDeep } from "lodash-es";
export default {
  orderStatusData,
  mixins: [kycMixin, cartMixin],
  components: {
    OrderBar: require("@/components/order/Bar").default,
    NoOrderFound: require("@/components/NoOrderFound").default,
    ProjectDropDownWrapper: require("@/components/projectDropDown/Wrapper").default,
    ProductCard: require("@/components/ProductCard").default,
    Shimmer: require("@/components/order/Shimmer").default,
    ListEmpty: require("@/components/ListEmpty").default,
    CustomDropDown: require("@/components/CustomDropDown").default,
    CustomProductListHeading: require("@/components/order/CustomProductListHeading").default,
    CustomOrderListHeading: require("@/components/order/CustomOrderListHeading").default,
    FilterBar: require("@/components/order/FilterBar").default,
    InvoiceStatusDropdown: require("@/components/invoice/InvoiceStatusDropdown").default,
    SearchFilter: require("@/components/SearchFilter").default,
    CustomDatePicker,
    InformationButton,
  },
  created() {
    this.getAllFilterOptions();
    this.getCount();
    if (this.projectId) this.filters.project_id = this.projectId;
    // this.get();
    this.applyOrderStatusTabFilter(this.$options.orderStatusData.under_process);
  },

  mounted() {},
  data() {
    return {
      isLoading: false,
      brandFilters: [],
      isBrandsLoading: false,
      searchQuery: "",
      orderStatusFilter: [],
      paymentStatusFilter: [],
      paymentStatusOptions: [],
      orderStatusOptions: [],
      orderList: [],
      deliveredCount: 0,
      activeCount: 0,
      requestedCount: 0,
      allCount: 0,
      isOrderListLoading: false,
      isFilterBarVisible: false,
      // isActiveFilterSelected: false,
      created_at: null,
      brands: [],
      totalOrder: 0,
      filters: {
        brand_id: null,
        date_range: null,
        order_status_id: null,
        payment_status_id: null,
        project_id: null,
        category_id: null,
        vendor_order_assign: null,
      },
      filterDate: null,
    };
  },
  watch: {
    searchQuery() {
      this.get();
    },
    projectId(newValue) {
      this.filters.project_id = newValue;
    },
    filters: {
      immediate: false,
      deep: true,
      handler: function () {
        this.get();
      },
    },
    "filters.category_id": {
      handler: function () {
        this.getAllBrands();
      },
    },
  },
  computed: {
    ...mapGetters({
      projectId: "user/getProjectId",
      categories: "categories",
    }),
    columns() {
      return [
        {
          name: "category",
          label: "Category",
        },
        { name: "brand_logo", label: "Brand" },
        {
          name: "products",
          label: "Product",
        },
        {
          name: "quantity",
          label: "Quantity",
        },
        { name: "price", label: "Price" },

        // { name: "PaymentStatus", label: "Payment Status", width: "15%" },
        // { name: "OrderStatus", label: "Order Status", width: "15%" },
        { name: "actions" },
      ];
    },
  },
  methods: {
    ...mapActions(["addToCart", "updateCart"]),

    getCount() {
      const filters = cloneDeep(this.filters);
      delete filters.vendor_order_assign;
      getOrderCount({
        filter: {
          ...filters,
          order_status_id: null,
        },
      }).then((res) => {
        this.deliveredCount = res.delivered;
        this.activeCount = res.under_process_order_count;
        this.requestedCount = res.buyer_order_confirmation_pending;
        this.allCount = res.total;
      });
    },
    allOrders() {
      this.filters.order_status_id = null;
      this.filters.vendor_order_assign = null;
    },

    downloadList() {
      this.isLoading = true;
      downloadHistory({
        filter: this.filters,
      })
        .then((res) => {
          window.open(res.url, "_blank");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    toggleProductDisplay(orderId) {
      const toggleClass = this.$refs[`order-product-table-${orderId}`][0].classList;

      if (toggleClass.contains("bi-hidden")) {
        toggleClass.remove("bi-hidden");
        toggleClass.add("bi-block");
      } else {
        toggleClass.remove("bi-block");
        toggleClass.add("bi-hidden");
      }
    },

    async get() {
      this.isOrderListLoading = true;

      this.getCount();

      try {
        const res = await getAll({
          search: this.searchQuery,
          filter: {
            ...this.filters,
          },
          limit: 25,
          include:
            "products,products.brand,products.categories,products.attributeOptions.attribute,orderStatus,paymentStatus,orderProducts.orderProductStatus,orderProducts.orderProductPaymentStatus",
        });
        this.totalOrder = res.meta.total;
        this.orderList = res.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isOrderListLoading = false;
      }
    },

    resetFilters() {
      this.brandFilters = [];
      this.orderStatusFilter = [];
      this.paymentStatusFilter = [];
      this.searchQuery = "";
      this.filters = {
        brand_id: null,
        date_range: null,
        order_status_id: null,
        payment_status_id: null,
        project_id: this.projectId,
        category_id: null,
        vendor_order_assign: null,
      };
    },
    applyOrderStatusTabFilter(id) {
      this.filters.order_status_id = id;
      this.filters.vendor_order_assign = null;
    },
    applyOrderConfirmationFilter(id) {
      this.filters.vendor_order_assign = id;
      this.filters.order_status_id = null;
    },
    // applyActiveOrderFilter() {
    //   this.isActiveFilterSelected = !this.isActiveFilterSelected;
    //   this.$options.orderStatusData.under_process.forEach((item) => {
    //     this.applyOrderFilter(item);
    //   });
    // },
    applyOrderFilter(id) {
      if (this.orderStatusFilter.includes(id)) {
        this.orderStatusFilter = this.orderStatusFilter.filter((item) => {
          return id !== item;
        });
      } else {
        this.orderStatusFilter.push(id);
      }

      this.filters.order_status_id = this.orderStatusFilter;
    },
    applyPaymentFilter(id) {
      if (this.paymentStatusFilter.includes(id)) {
        this.paymentStatusFilter = this.paymentStatusFilter.filter((item) => {
          return id !== item;
        });
      } else {
        this.paymentStatusFilter.push(id);
      }

      this.filters.payment_status_id = this.paymentStatusFilter;
    },
    applyBrandFilter(id) {
      if (this.brandFilters.includes(id)) {
        this.brandFilters = this.brandFilters.filter((item) => {
          return id !== item;
        });
      } else {
        this.brandFilters.push(id);
      }

      this.filters.brand_id = this.brandFilters;
    },

    async getAllBrands() {
      this.isBrandsLoading = true;
      const categoryId = this.filters.category_id;

      if (categoryId && this.projectId) this.brands = await getBrands(categoryId, this.projectId);

      this.isBrandsLoading = false;
    },
    async getAllFilterOptions() {
      this.getAllBrands();

      this.paymentStatusOptions = await getPaymentStatusOptions();
      this.orderStatusOptions = await getOrderStatusOptions();
    },
    exportOrders() {},
  },
};
</script>

<style scoped>
.header-actions {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.search-input {
  height: 40px;
  border: 1px solid #eceff1;
}
.sp-table {
  padding: 0px;
}

.filter.button {
  background: var(--color--grey--lightest-double-ultra) !important;
}

.filter.button--active {
  background: var(--color--secondary--lightest-ultra) !important;
  @apply bi-text-secondary-darker hover:bi-text-secondary-default hover:bi-bg-secondary-lighter;
}

.filter_tab.button--active {
  @apply bi-bg-success-lightest;
}
</style>
