<template>
  <modal size="sm" title="Additional Instructions" id="additional-instructions-modal" @close="resetModal">
    <template #default>
      <s-field>
        <s-textarea rows="4" placeholder="About delivery schedule,Other Brands, Packaging, etc..." v-model="message" />
      </s-field>
      <div class="bi-flex bi-justify-center bi-mt-4">
        <s-button @click.native="onSubmit" label="Get a best quote" color="secondary" />
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      message: "",
    };
  },

  methods: {
    resetModal() {
      this.message = "";
    },
    onSubmit() {
      this.$root.$emit("place-order", this.message);
      this.resetModal();
      this.$root.$emit("shilp-modal-close", "additional-instructions-modal");
    },
  },
};
</script>

<style lang="scss" scoped></style>
