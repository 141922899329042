var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header bi-h-full d-bi-flex bi-flex-col bi-overflow-hidden"},[_c('portal',{attrs:{"to":"header-actions"}},[_c('div',[_c('div',{staticClass:"bi-hidden md:bi-block"},[(_vm.cartProducts && _vm.cartProducts.length)?_c('s-button',{attrs:{"label":"Clear Truck","color":"danger","icon":"IconMdiTrashCanOutline","theme":"outline"},nativeOn:{"click":function($event){return _vm.clearTruckItems.apply(null, arguments)}}}):_vm._e()],1),_c('div',{staticClass:"bi-block md:bi-hidden"},[(_vm.cartProducts && _vm.cartProducts.length)?_c('s-button',{attrs:{"label":"","color":"danger","icon":"IconMdiTrashCanOutline","theme":"outline"},nativeOn:{"click":function($event){return _vm.clearTruckItems.apply(null, arguments)}}}):_vm._e()],1)])]),_c('ProjectDropDownWrapper'),(_vm.cartProducts && _vm.cartProducts.length)?[_c('div',{staticClass:"table bi-mb-24"},[_c('sp-list',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreTruckItems),expression:"loadMoreTruckItems"}],ref:"list",attrs:{"filters":_vm.filters,"perPage":25,"pagination-mode":"infinite","infinite-scroll-distance":"10","actions":[],"attrs":_vm.columns,"sortBy":"price","sortOrder":"asc","endpoint":"rest:products","params":_vm.params},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('list-empty')]},proxy:true}],null,false,2022369255)},[_c('div',{staticClass:"bi-overflow-x-scroll"},[_c('sp-list-table',{staticClass:"truck-table",scopedSlots:_vm._u([{key:"brand",fn:function(ref){
var item = ref.item;
return [(item && item.brand && item.brand.logo)?_c('BrandLogo',{attrs:{"brand":item.brand}}):_vm._e()]}},{key:"price",fn:function(ref){
var item = ref.item;
return [(item.price)?_c('div',{staticClass:"bi-flex bi-items-center",class:[
                  {
                    'bi-animate-pulse': _vm.isPriceChangedForParticularProduct(item),
                  },
                  { 'bi-text-danger-default ': _vm.isPriceIncreased(item) },
                  { 'bi-text-success-default': _vm.isPriceDecreased(item) } ]},[(_vm.categoryNameInCart == 'AAC Blocks')?_c('AacBlocksPrice',{key:("product-price-" + (item.id)),attrs:{"gstPercentage":_vm.getGstPercentage(item.attributeOptions),"price":item.price,"unit":item.unit}}):_c('product-price',{attrs:{"price":item.price,"unit":item.unit}}),(_vm.isPriceChangedForParticularProduct(item) && _vm.isPriceIncreased(item))?_c('s-button',{staticStyle:{"cursor":"default"},attrs:{"theme":"trn","color":"danger","shape":"circle","icon":"IconMdiArrowUp"}}):_vm._e(),(_vm.isPriceChangedForParticularProduct(item) && _vm.isPriceDecreased(item))?_c('s-button',{staticStyle:{"cursor":"default"},attrs:{"theme":"trn","color":"success","shape":"circle","icon":"IconMdiArrowDown"}}):_vm._e(),(_vm.isPriceChangedForParticularProduct(item))?_c('span',[_vm._v(" ₹ "+_vm._s(_vm.getPriceDifference(item))+" ")]):_vm._e()],1):_vm._e()]}},{key:"total",fn:function(ref){
                var item = ref.item;
return [(item.price)?_c('div',{staticClass:"bi-flex bi-gap-2"},[(_vm.categoryNameInCart == 'AAC Blocks')?_c('AacBlocksPrice',{key:("product-total-price-" + (item.id)),attrs:{"gstPercentage":_vm.getGstPercentage(item.attributeOptions),"price":_vm.getTotalPriceOfOrderedProduct(item)}}):_c('product-price',{attrs:{"price":_vm.getTotalPriceOfOrderedProduct(item)}}),_c('v-popover',{attrs:{"trigger":"click"},scopedSlots:_vm._u([{key:"popover",fn:function(){return [(item.categories[0].name == 'AAC Blocks')?_c('AacBlocksPriceBreakUp',{attrs:{"gstPercentage":_vm.getGstPercentage(item.attributeOptions),"gstAmount":_vm.getAacBlocksGstPrice(_vm.getTotalPriceOfOrderedProduct(item), _vm.getGstPercentage(item.attributeOptions)),"basePrice":_vm.getTotalPriceOfOrderedProduct(item)}}):_c('PriceBreakUp',{attrs:{"gstPercentage":_vm.getGstPercentage(item.attributeOptions),"gstAmount":_vm.getGstPrice(_vm.getTotalPriceOfOrderedProduct(item), _vm.getGstPercentage(item.attributeOptions)),"basePrice":_vm.getBasePrice(_vm.getTotalPriceOfOrderedProduct(item), _vm.getGstPercentage(item.attributeOptions))}})]},proxy:true}],null,true)},[_c('s-button',{attrs:{"icon":"IconMdiInformationOutline","size":"md","color":"secondary","theme":"link"}})],1)],1):_c('p',[_c('OrderStatusTag',{attrs:{"name":"RFP","label":"RFP"}})],1)]}},{key:"actions",fn:function(ref){
                var item = ref.item;
return [(_vm.getCategoryId(item) == 1)?_c('CementCounter',{key:("product-counter-" + (item.id)),attrs:{"id":item.id,"categoryId":item.categories[0].id,"price":item.price,"quantityOfOrder":_vm.getProductQuantity(item.id),"unit":item.unit,"brandId":item.brand.id,"gstPercentage":_vm.getGstPercentage(item.attributeOptions),"isGstIncluded":_vm.isGstIncluded(item.attributeOptions)}}):_vm._e(),(_vm.getCategoryId(item) == 2)?_c('TmtBarCounter',{key:("product-counter-" + (item.id)),attrs:{"id":item.id,"categoryId":item.categories[0].id,"unit":item.unit,"price":item.price,"quantityOfOrder":_vm.getProductQuantity(item.id),"brandId":item.brand.id,"gstPercentage":_vm.getGstPercentage(item.attributeOptions),"isGstIncluded":_vm.isGstIncluded(item.attributeOptions)}}):_vm._e(),(_vm.getCategoryId(item) == 3)?_c('AacBlocksCounter',{key:("product-counter-" + (item.id)),attrs:{"id":item.id,"unit":item.unit,"categoryId":item.categories[0].id,"price":item.price + _vm.getAacBlocksGstPrice(item.price, _vm.getGradeAttributeObject(item.attributeOptions).gst_percentage),"quantityOfOrder":_vm.getProductQuantity(item.id),"brandId":item.brand.id,"gstPercentage":_vm.getGstPercentage(item.attributeOptions),"isGstIncluded":_vm.isGstIncluded(item.attributeOptions)}}):_vm._e()]}}],null,false,2077317376)})],1)])],1),_c('checkout',{attrs:{"params":_vm.params,"totalPrice":_vm.cartProducts[0].price,"filters":_vm.filters},on:{"price-updated-from-api":_vm.refreshTableData}})]:[_c('truck-empty')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }