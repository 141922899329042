var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.orders && _vm.orders.length)?_c('div',{directives:[{name:"shilp-loader",rawName:"v-shilp-loader.overlay",value:(_vm.isLoading),expression:"isLoading",modifiers:{"overlay":true}}],staticClass:"bi-p-4"},[_c('header',{staticClass:"bi-flex bi-justify-between bi-flex-wrap bi-items-center bi-mb-2 bi-leading-8"},[_c('span',{staticClass:"bi-text-base bi-text-grey-default"},[_vm._v("Order History")]),_c('s-button',{staticStyle:{"bi-font-weight":"500 !important"},attrs:{"theme":"link","label":"View All","color":"secondary","size":"md","align":"right","to":{ name: 'orders' }}})],1),(!_vm.isLoading)?_c('splide',{ref:"slider",staticClass:"order-slider",attrs:{"options":{
        gap: 8,
        perPage: _vm.perPage,
        arrows: _vm.totalNoOfOrders > _vm.perPage,
        perMove: 1,
        pagination: false,
        width: 'calc(100vw - 290px)',
        rewind: false,
        breakpoints: {
          1440: {
            arrows: _vm.totalNoOfOrders > _vm.perTabletPage,
            perPage: _vm.perTabletPage,
          },
          768: {
            arrows: _vm.totalNoOfOrders > _vm.perMobilePage,
            perPage: _vm.perMobilePage,
            width: 'calc(100vw - 40px)',
          },
        },
      }}},[_vm._l((_vm.orders),function(item,index){return [(item.products && item.products.length)?_c('splide-slide',{key:index,staticClass:"order-history-card md:bi-px-4 md:bi-py-2 bi-p-3 bi-items-center md:bi-rounded-md bi-flex bi-justify-between bi-flex-nowrap md:bi-gap-3 bi-gap-2 md:bi-border bi-border-grey-lightest-double-ultra bi-border-r"},[_c('BrandLogo',{attrs:{"brand":item.products[0].brand,"size":"lg"}}),_c('div',{staticClass:"order-history-card__content bi-flex-auto"},[_c('h3',{staticClass:"bi-text-base-lg md:bi-font-normal bi-font-semibold bi-text-grey-darkest-ultra"},[_vm._v(" "+_vm._s(item.products[0].categories[0].name)+" ")]),_c('div',{staticClass:"bi-flex bi-justify-between bi-flex-nowrap bi-flex-auto bi-items-center"},[_c('div',[_c('p',{staticClass:"bi-font-medium bi-text-grey-default bi-text-md"},[_vm._v(" Quantity : "+_vm._s(_vm.getTotalQuantity(item.products))+" "+_vm._s(_vm.getUnit(item.products[0]))+" ")]),_c('p',{staticClass:"bi-text-xs bi-text-grey-default bi-font-medium"},[_vm._v(" "+_vm._s(_vm.$formatDate(item.created_at))+" ")])]),_c('div',{staticClass:"bi-text-right add-to-cart"},[_c('s-button',{attrs:{"size":"md","icon":"IconMdiPlus","color":"success","shape":"square","rounded":""},nativeOn:{"click":function($event){return _vm.goToProductListByBrand(
                      item.products[0].categories[0].id,
                      item.products[0].brand.id,
                      item.products[0].attributeOptions,
                      item.products
                    )}}})],1)])])],1):_vm._e()]})],2):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }