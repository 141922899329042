<template>
  <div class="bi-h-full">
    <sign>
      <template #default>
        <h2 class="bi-text-primary-default bi-leading-10 bi-font-semibold">
          Welcome
          <span class="bi-block">Back!</span>
        </h2>
        <div class="bi-mt-2 bi-mb-4 bi-font-medium">
          <span class="bi-text-grey-default"> Don't have an account? </span>
          <s-button class="bi-ml-1" label="Sign Up" color="secondary" align="right" theme="link" :to="{ name: 'signup' }" />
        </div>
        <item-form
          :item="false"
          :fields="fields"
          :save="save"
          width="125"
          :footer="true"
          :redirect="false"
          ref="item"
          :form-state.sync="formState"
          :notify="false"
          :gap="2"
          :footerClassList="['bi-mt-4', 'bi-justify-between']"
        >
          <template #default="{ form }">
            <!--- UserName --->

            <!-- <Username v-model="form.username"  /> -->
            <s-field-validate name="Username" rules="required">
              <s-textbox :value="form.username" @input="checkUsernameInput" type="text" placeholder="Username or GST Number" />
            </s-field-validate>

            <!--- Password --->
            <s-field-validate name="Password" :password-toggle="true" rules="required">
              <s-textbox v-model="form.password" type="password" placeholder="Password" />
            </s-field-validate>

            <!-- OTP -->
            <ValidationProvider rules="required">
              <otp-handler v-model.number="form.code" :get="getOtp" :showSendToPartnerField="true" :classList="['bi-mt-0']" />
            </ValidationProvider>
            <!-- </div> -->
          </template>
          <template #footer="{ saving, saveItem, validate }">
            <s-button
              align="right"
              color="secondary"
              :disabled="validate.invalid"
              size="lg"
              label="Sign In"
              :loader="saving"
              @click.native="saveItem"
            />
            <s-button label="Forgot Password?" color="secondary" align="right" theme="link" :to="{ name: 'forget-password' }" />
          </template>
        </item-form>
      </template>
    </sign>
  </div>
</template>

<script>
import login from "@/graph/login.gql";
import verifyCode from "@/graph/otp/verify.gql";
import { mapActions, mapMutations } from "vuex";
import { checkAccountAdminCreate } from "@/api/account.js";
import { getDeviceId } from "@/utils/common.js";
export default {
  components: {
    Sign: require("@/components/Sign").default,
    OtpHandler: require("@/components/OtpHandler").default,
    // Username: require("@/components/Username").default,
  },
  data() {
    return {
      fields: ["username", "password", "code"],
      formState: {},
      mutation: login,
    };
  },
  created() {
    const token = localStorage.getItem("bi-token");
    if (token) this.$router.push("/");
  },
  methods: {
    ...mapActions(["resetCart"]),
    ...mapMutations({ resetState: "user/resetState" }),
    getOtp(params) {
      // Params contains { defaultPartnerOtpSend value }

      if (this.formState.username) {
        return this.$graphPublic(login, { ...this.formState, ...params }).then((res) => {
          return res.login;
        });
      } else {
        this.$shilp.notify({
          title: "Please fill required fields",
          type: "danger",
        });
        return Promise.reject(false);
      }
    },

    checkUsernameInput($event) {
      this.formState.username = $event;

      if ($event && $event.length == 15) {
        checkAccountAdminCreate($event).then((res) => {
          if (res) {
            this.$router.push({
              name: "forget-password",
              query: { gst_no: $event },
            });
          }
        });
      }
    },

    async save() {
      let res = await this.$graphPublic(verifyCode, {
        ...this.formState,
        deviceType: "web",
        codeFor: "sign_in",
        deviceId: await getDeviceId(),
      });
      this.afterLogin(res.verifyCode);
    },
    afterLogin({ token, showKYCApproveNotification }) {
      if (token) {
        localStorage.setItem("bi-token", token);
      }

      if (showKYCApproveNotification)
        this.$shilp.notify({
          title: "KYC Approved",
          type: "accent",
          duration: 8000,
        });
      // Resting cart to solve issue of Cart data getting used in different accounts without loggin out.

      this.resetCart();
      // resetting user state to solve issue of old storage and items getting processed.
      this.resetState();
      this.$router.push("/");
    },
  },
};
</script>

<style>
.footer-actions {
  display: flex;
  justify-content: space-between;
}
</style>
