<template>
  <div class="company bi-flex bi-flex-nowrap bi-items-center md:bi-pl-4 bi-h-14">
    <div class="company__icon bi-hidden sm:bi-block"><img src="@/assets/img/truck.png" alt /></div>
    <div class="company-content">
      <div class="company__title">
        <v-select
          class="select__project"
          placeholder="Select Projects"
          v-model="projectId"
          :clearable="false"
          :options="options"
          :reduce="(project) => project.value"
        >
        <template v-slot:option="option">
          {{ option.label }} <span v-if="option.isDefault">(Primary)</span>
        </template>
          <template #footer v-if="showAddress">
            <p
              class="company-addr bi-text-grey-lighter bi-font-medium bi-text-sm bi-overflow-ellipsis bi-overflow-hidden bi-pl-2 bi-whitespace-nowrap"
              :title="address"
            >
              {{ address }}
            </p>
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
import get from "@/graph/projects/collection.gql";
import { mapGetters, mapActions } from "vuex";
import { eventBus } from '@/plugins/event-bus.js';
export default {
  props: {
    showAddress: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [],
      projectId: null,
      address: "",
    };
  },
  computed: {
    ...mapGetters({
      projectIdFromVuexStore: "user/getProjectId",
      companyId: "user/companyId",
    }),
  },
  // below watch is necessary in order to have address displayed according to selected value from dropdown.
  watch: {
    companyId() {
      this.getProjectList();
    },
    projectIdFromVuexStore(newValue){
      if(newValue){
        this.resetCart();
        this.$router.push({ name: "home"})
      }
      
    },
    projectId(newValue) {
      // filter method returns array with element having projectId in options array.
      if (newValue) {
        this.setProjectId(newValue);
        eventBus.$emit('refreshHydration', newValue);
      }
      const temp = this.options.filter((item) => {
        return item.value == newValue;
      });
      if (temp && temp.length) {
        // below code selects first item from filtered array to display it in dropdown.
        this.address = temp[0].address;
      }
    },
  },
  mounted() {
    if (this.companyId) this.getProjectList();
  },
  methods: {
    ...mapActions({
      setProjectId: "user/setProjectId"
    }),
    ...mapActions(["resetCart"]),
    async getProjectList() {
      let res = await this.$graph(get, {
        companyId: this.companyId,
        // limit -1 is for telling api to return only data , no pagination data needed.
        limit: -1,
      });
      // map method returns array with object having label , value and address as properties for dropdown.
      this.options = res.projects.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
          isDefault: item.isDefault,
          address: item.address,
        };
      });
      if (this.projectIdFromVuexStore) {
        // below logic is for setting selected project id at the time of order in order list screen instead of default project orders
        this.projectId = this.projectIdFromVuexStore;
      } else {
        // below logic is for setting Default Project

        if (this.options && this.options.length) {
          this.setDefaultProject(this.options);
        }
      }
    },

    setDefaultProject(projectOptions) {
      const defaultProject = projectOptions.find((item) => {
        return item.isDefault == 1;
      });
      this.projectId = defaultProject.value;
    },
  },
};
</script>

<style lang="scss">
.company {
  &:hover {
    cursor: pointer !important;
  }
  // @apply hover:bi-cursor-pointer;
}
.company-content {
  padding-left: 5px;
  // width: 500px;
  width: auto;
  text-overflow: ellipsis;
  display: block;
  margin-top: -4px;
  text-transform: capitalize;
}

.select__project {
  .vs__dropdown-toggle {
    border: 0;
    width: 210px;
    &:hover {
      cursor: pointer !important;
    }
  }
}

.company__icon {
  width: 31px;
  height: auto;
}
.company .company-content {
  margin-left: 0px !important;
}
.company-addr {
  width: 210px;
}

// below styles are applied to fix problem of dropdown value if too long , then UI breaks.
.vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 25px); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
}
.vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .company-addr {
    // width: 250px;
  }
  .select__project {
    .vs__dropdown-toggle {
      // width: 250px;
    }
  }
}
@media screen and (max-width: 500px) {
  .company-addr {
    // width: 150px;
  }
  .select__project {
    .vs__dropdown-toggle {
      // width: 150px;
    }
  }
}
@media screen and (max-width: 330px) {
  .company-content {
    padding-left: 0 !important;
  }
  .company-addr {
    // width: 140px;
  }
  .select__project {
    .vs__dropdown-toggle {
      // width: 140px;
    }
  }
}
</style>
