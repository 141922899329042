<template>
  <sign action="signup" class="signup">
    <h2 class="bi-text-primary-default bi-font-display bi-leading-10">
      Create <br />
      Account
    </h2>

    <div class="bi-mt-2 bi-mb-4 bi-font-medium">
      <span class="bi-text-grey-default"> Already a User? </span>

      <s-button class="bi-ml-1" label="Sign in" color="secondary" align="right" theme="link" :to="{ name: 'login' }" />
    </div>
    <item-form
      class="bi-pb-0 bi-mb-4"
      :form-state.sync="formState"
      :save="signup"
      :has-reset="false"
      :notify="false"
      :fields="fields"
      width="100"
      ref="item"
      :gap="2"
    >
      <template #default="{ form }">
        <!-- GST NO  -->
        <div class="bi-mb-2">
          <div class="gstin-no">
            <s-field-validate label="GSTIN Number" name="GSTIN Number" rules="required|gst">
              <UpperCaseTextBoxInput type="text" :maxLength="15" v-model="form.gstNo" @input="form.gstNo.length == 15 && getGstData(form.gstNo)" />
            </s-field-validate>
          </div>

          <label
            v-if="companyName"
            class="bi-text-black bi-rounded-sm bi-font-medium bi-font-display bi-block bi-text-center bi-py-2 bi-bg-grey-lightest-double-ultra bi-text-md"
          >
            {{ companyName }}</label
          >
        </div>

        <!-- Contact Person Name  -->
        <div class="bi-space-y-2">
          <s-field-validate name="Name" rules="required">
            <TextBoxCapitalize placeholder="Contact Person Name" v-model="form.name" />
          </s-field-validate>
          <!-- Mobile No  -->

          <CustomMobileInput v-model="form.mobileNo" />
          <!-- Email  -->
          <s-field-validate name="Email" rules="required|email">
            <s-textbox v-model="form.email" type="text" placeholder="Email" />
          </s-field-validate>

          <!-- Set GST as username -->
          <s-field label="Set GST as my username" inline class="bi-items-center">
            <s-switch
              id="set-gst-as-username-switch"
              key="set-gst-as-username-switch"
              color="primary"
              size="xs"
              :on-value="true"
              :off-value="false"
              shape="pill"
              @input="setUsername"
            />
          </s-field>

          <!--  User Name -->

          <Username v-model="form.username" :disabled="isGstUsername" />
          <!-- Password -->
          <s-field-validate name="Password" rules="required|min:8" :password-toggle="true">
            <s-textbox v-model="form.password" type="password" placeholder="Password" />
          </s-field-validate>
          <!-- Confirm Password -->
          <s-field-validate :password-toggle="true" :rules="{ required: true, password: form.password }" name="Confirm Password">
            <s-textbox v-model="form.passwordConfirmation" type="password" placeholder="Confirm Password" />
          </s-field-validate>

          <!--  OTP -->
          <ValidationProvider rules="required">
            <otp-handler v-model="form.code" :get="getOtp" />
          </ValidationProvider>
        </div>
      </template>

      <template #footer="{ saving }">
        <div class="bi-shrink">
          <s-button color="secondary" :disabled="saving || !gstNoValid" label="Sign Up" size="lg" type="submit" :loader="saving" />
        </div>
      </template>
    </item-form>
  </sign>
</template>

<script>
import signup from "@/graph/signup.gql";
import getCompany from "@/graph/company/getCompanyName.gql";
import getCode from "@/graph/otp/get.gql";
import { mapActions } from "vuex";
import { getDeviceId } from "@/utils/common.js";

export default {
  components: {
    Sign: require("@/components/Sign").default,
    OtpHandler: require("@/components/OtpHandler").default,
    TextBoxCapitalize: require("@/components/TextBoxCapitalize").default,
    UpperCaseTextBoxInput: require("@/components/UpperCaseTextBoxInput").default,
    CustomMobileInput: require("@/components/CustomMobileInput").default,
    Username: require("@/components/Username").default,
  },
  data() {
    return {
      gstNoValid: false,
      formState: {},
      companyName: null,
      fields: ["gstNo", "name", "mobileNo", "email", "username", "password", "passwordConfirmation", "code"],
      isGstUsername: false,
    };
  },
  methods: {
    ...mapActions(["resetCart"]),
    setUsername(setUsername) {
      if (setUsername) {
        this.isGstUsername = true;
        this.formState.username = this.formState.gstNo;
      } else {
        this.formState.username = "";
        this.isGstUsername = false;
      }
    },

    getOtp() {
      if (this.formState.mobileNo == null || this.formState.mobileNo.length <= 9) {
        this.$shilp.notify({
          title: "Please Enter Valid Mobile Number",
          type: "danger",
        });

        return new Promise((resolve, reject) => {
          reject(false);
        });
      }

      return this.$graphPublic(getCode, {
        input: {
          mobileNo: this.formState.mobileNo,
          codeFor: "sign_up",
          email: this.formState.email,
        },
      }).then((res) => {
        return res.sendCode.code;
      });
    },
    getGstData(gstNo) {
      this.$graphPublic(getCompany, {
        gstNo,
      })
        .then((res) => {
          this.gstNoValid = true;
          this.companyName = res.companyFromGstNo.companyName;
        })
        .catch((error) => {
          this.gstNoValid = false;
          this.companyName = "";

          // REFACTOR Make a common wrapper method for success and danger notifications
          this.$shilp.notify({
            title: error[0].message,
            type: "danger",
            duration: 6000,
          });
        });
    },
    async signup(id, data) {
      return this.$graphPublic(signup, {
        input: {
          ...data,
          deviceType: "web",
          deviceId: await getDeviceId(),
        },
      }).then((res) => {
        const token = res.signUp.token;
        this.afterSignup(token);
        return true;
      });
    },

    afterSignup(token) {
      if (token) {
        localStorage.setItem("bi-token", token);
      }
      // Resting cart to solve issue of Cart data getting used in different accounts without loggin out.
      this.resetCart();
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
.otp-input {
  display: flex;
  .field-validate {
    flex: 1;
  }
}
</style>
