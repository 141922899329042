<template>
  <div class="bi-flex bi-items-center bi-gap-1 md:bi-gap-3">
    <div class="bi-block bi-pt-1 md:bi-hidden">
      <v-popover>
        <template #popover>
          <AvailableCredits />
        </template>
        <s-button icon="IconMdiInformationOutline" size="lg" color="light" theme="link" />
      </v-popover>
    </div>
    <div class="bi-hidden md:bi-block">
      <AvailableCredits />
    </div>
    <s-button-group class="header-items bi-flex bi-gap-3" shape="circle">
      <s-button icon="IconMdiTruckOutline" v-tooltip="'Truck'" :to="{ name: 'truck' }" size="lg" class="bi-relative" tabindex="6">
        <template #after>
          <CartTotalItems />
        </template>
      </s-button>

      <div class="bi-hidden md:bi-flex bi-gap-3">
        <s-button size="lg" icon="IconMaterialSymbolsContactPhoneOutline" v-tooltip="'Inquiry'" :to="{ name: 'inquiry-section' }" tabindex="7" />
        <s-button
          size="lg"
          v-tooltip="'Account'"
          icon="IconMdiAccountCircleOutline"
          :to="{ name: 'account-page' }"
          tabindex="8"
          :disabled="getAdminId() !== null"
        />

        <s-button icon="IconIcSharpLogOut" v-tooltip="'Logout'" tabindex="9" size="lg" @click.native="$shilp.modal.open('log-out-account-modal')" />
      </div>
    </s-button-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import kycMixin from "@/mixins/kycMixin";
import { getAdminId } from "@/utils/adminOrder";

export default {
  mixins: [kycMixin],
  components: {
    CartTotalItems: require("@/components/cart/CartTotalItems").default,
    AvailableCredits: require("@/components/account/AvailableCredits").default,
    ...mapGetters({
      availableCredit: "user/availableCredit",
    }),
  },
  methods: {
    getAdminId,
  },
};
</script>

<style lang="scss" scoped></style>
