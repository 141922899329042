<template>
  <sub-layout>
    <template #default>
      <portal to="header-actions">
        <div>
          <div class="bi-hidden md:bi-block">
            <p>
              Last Login: <span class="bi-font-bold">{{ $formatDate(lastLoginDate) }} </span>
            </p>
          </div>
          <div class="bi-block md:bi-hidden">
            <v-popover trigger="click">
              <template #popover>
                <p>
                  Last Login: <span class="bi-font-bold">{{ $formatDate(lastLoginDate) }} </span>
                </p>
              </template>
              <s-button icon="IconMdiInformationOutline" size="md" color="primary" theme="link" class="bi-align-middle"/>
            </v-popover>
          </div>
        </div>
      </portal>
      <router-view></router-view>
      <div class="bi-block md:bi-hidden contact-us-button">
        <v-popover trigger="click">
          <template #popover>
            <contact-us style="width: 250px" />
          </template>
          <s-button icon="IconMdiMessageQuestionOutline" size="lg" color="secondary" theme="" shape="circle" />
        </v-popover>
      </div>
    </template>
    <template #menu>
      <account-sidebar />
    </template>

    <template #mobile-menu>
      <account-mobile-bar />
    </template>

    <template #right-side>
      <div class="bi-hidden md:bi-block">
        <contact-us />
      </div>
    </template>

    <template #bottom v-if="!isKycApproved()">
      <kyc-status-bar />
    </template>
  </sub-layout>
</template>

<script>
import { mapGetters } from "vuex";
import kycMixin from "@/mixins/kycMixin";
export default {
  mixins: [kycMixin],
  components: {
    AccountSidebar: require("@/components/accountSidebar/Sidebar").default,
    AccountMobileBar: require("@/components/accountSidebar/MobileBar").default,
    ContactUs: require("@/components/ContactUs").default,
    KycStatusBar: require("@/components/KycStatusBar").default,
  },
  computed: {
    ...mapGetters({
      status: "user/status",
      lastLoginDate: "user/getLastLoginDate",
    }),
  },
};
</script>

<style lang="scss" scoped>
.contact-us-button {
  position: fixed;
  bottom: 3%;
  right: 3%;
}
</style>
