<template>
  <div class="bi-bg-grey-lightest-double-ultra bi-text-grey-darkest-ultra bi-h-14 bi-p-2 bi-text-base bi-font-normal bb-lightest">
    <div>
      {{ product.attributeOptions[0].name }}
    </div>

    <div>
      {{ product.attributeOptions[1].name }}
    </div>

    <div>
      {{ product.dispatch_quantity + " " + product.unit }}
    </div>
    <div>
      <!-- <AacBlocksPrice
        v-if="categoryName == 'AAC Blocks'"
        :gstPercentage="getGstPercentage(product.attributeOptions)"
        :price="product.price"
        :unit="product.unit"
      /> -->
      <product-price :price="product.price" v-if="product.price" />
      <p v-else><OrderStatusTag name="RFP" label="RFP" /></p>
    </div>

    <div class="bi-flex bi-gap-2" v-if="product.total">
      <TotalPrice :price="product.total" />
      <v-popover trigger="hover">
        <template #popover>
          <PriceBreakUp
            :gstPercentage="product.gst_percentage"
            :gstAmount="product.gst_total_amount"
            :basePrice="product.total - product.gst_total_amount"
          />
        </template>
        <!-- <s-icon class="bi-text-secondary-default bi-w-5 bi-h-5" name="IconMdiInformationOutline" /> -->
        <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" />
      </v-popover>
    </div>
    <p v-else><OrderStatusTag name="RFP" label="RFP" /></p>

    <!-- <div>
      <order-status-tag
        :label="orderStatus.display_name"
        :name="orderStatus.name"
      />
    </div> -->

    <!-- Payment Status -->
    <!-- <div v-if="orderStatus.name == 'pending'">N/A</div>
    <div v-else>
      <payment-status-tag
        :label="paymentStatus.display_name"
        :name="paymentStatus.name"
      />
    </div> -->

    <!-- <div>
      <div>
        <s-button
          label="Reorder"
          icon="IconIcRoundRefresh"
          color="secondary"
          class="text-bold"
          theme="link"
          @click.native="reorder(product, orderStatus, paymentStatus)"
        ></s-button>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import cartMixin from "@/mixins/cartMixin";

export default {
  data() {
    return {};
  },
  props: ["product", "orderStatus", "paymentStatus"],
  mixins: [cartMixin],
  components: {
    ProductPrice: require("@/components/ProductPrice").default,
    TotalPrice: require("@/components/TotalPrice").default,
    // AacBlocksPrice: require("@/components/AacBlocksPrice").default,
    PriceBreakUp: require("@/components/products/PriceBreakUp").default,
    // AacBlocksPriceBreakUp: require("@/components/products/AacBlocksPriceBreakUp").default,

    // BrandLogo: require("@/components/brandLogo/Component.vue").default,
    // PaymentStatusTag: require("@/components/order/PaymentStatusTag").default,
    OrderStatusTag: require("@/components/order/OrderStatusTag").default,
  },

  computed: {
    categoryName() {
      return this.product.categories[0].name;
    },
  },
  methods: {
    ...mapActions(["addToCart", "updateCart", "setTotalNoOfProductsInCart", "setCategoryIdInCart", "setCategoryNameInCart"]),

    // reorder(product) {
    //   const isCategorySameInCart = this.checkIfCategoryIsSameInCart(
    //     this.product.categories[0].id
    //   );

    //   if (isCategorySameInCart) {
    //     this.addToCart({
    //       product_id: product.id,
    //       quantity: product.quantity,
    //       price: product.price,
    //       total: product.total,
    //       orderStatus: this.orderStatus,
    //       paymentStatus: this.paymentStatus,
    //     });

    //     this.setCategoryIdInCart(this.product.categories[0].id);
    //     this.setCategoryNameInCart(this.product.categories[0].name);

    //     this.setTotalNoOfProductsInCart(this.totalNoOfProductsInCart + 1);

    //     this.$router.push({ name: "truck" });
    //   } else {
    //     this.alertDifferentOrderCategoryNotAllowed();
    //   }
    // },
  },
};
</script>

<style lang="scss">
.v-list__header {
  padding: 0px !important;
}
</style>
