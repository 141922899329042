<template>
  <div class="bi-text-right sm:bi-border-r sm:bi-border-grey-lighter bi-pr-4">
    <div class="price-tag">
      <label class="bi-text-md md:bi-text-2xl bi-text-grey-darkest-ultra bi-font-display bi-font-normal">
        <div class="bi-font-bold bi-min-w-max">
          {{ totalCartPrice ? $formatPrice(totalCartPrice) : "RFP" }}
        </div>
      </label>
      <span
        v-if="totalCartPrice"
        class="bi-flex bi-items-start bi-justify-end bi-gap-2 bi-text-xs md:bi-text-sm bi-text-grey-darkest-ultra bi-font-display bi-font-normal bi-min-w-max"
      >
        <v-popover trigger="hover">
          <ShowBreakup />
          <template #popover>
            <AdditionalChargesInfo
              :basePrice="totalBasePrice"
              :gstPrice="totalGstPrice"
              :subtotal="totalCartPrice"
              :grandTotal="totalCartPrice"
              :loading="brandLoadingOption"
              :unloading="brandUnloadingOption"
              :freight="brandFreightOption"
            />
          </template>
        </v-popover>
      </span>
    </div>

    <!-- type="submit" -->
  </div>
</template>

<script>
import cartMixin from "@/mixins/cartMixin";

export default {
  props: {},
  components: {
    AdditionalChargesInfo: require("@/components/order/AdditionalChargesInfo").default,
    ShowBreakup: require("@/components/cart/ShowBreakup").default,
  },
  mixins: [cartMixin],
};
</script>

<style></style>
