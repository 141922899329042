<template>
  <div class="bi-p-4" v-shilp-loader.overlay="isLoading" v-if="orders && orders.length">
    <header class="bi-flex bi-justify-between bi-flex-wrap bi-items-center bi-mb-2 bi-leading-8">
      <span class="bi-text-base bi-text-grey-default">Order History</span>

      <s-button
        theme="link"
        label="View All"
        color="secondary"
        style="bi-font-weight: 500 !important"
        size="md"
        align="right"
        :to="{ name: 'orders' }"
      />
    </header>
    <!-- below splide width option is provided to handle problem of Splide UI rendering extreme large infinite width therefore  
therefore setting width option so that it does not apply large width -->

    <splide
      v-if="!isLoading"
      class="order-slider"
      ref="slider"
      :options="{
        gap: 8,
        perPage: perPage,
        arrows: totalNoOfOrders > perPage,
        perMove: 1,
        pagination: false,
        width: 'calc(100vw - 290px)',
        rewind: false,
        breakpoints: {
          1440: {
            arrows: totalNoOfOrders > perTabletPage,
            perPage: perTabletPage,
          },
          768: {
            arrows: totalNoOfOrders > perMobilePage,
            perPage: perMobilePage,
            width: 'calc(100vw - 40px)',
          },
        },
      }"
    >
      <template v-for="(item, index) in orders">
        <splide-slide
          :key="index"
          v-if="item.products && item.products.length"
          class="order-history-card md:bi-px-4 md:bi-py-2 bi-p-3 bi-items-center md:bi-rounded-md bi-flex bi-justify-between bi-flex-nowrap md:bi-gap-3 bi-gap-2 md:bi-border bi-border-grey-lightest-double-ultra bi-border-r"
        >
          <!-- <pre>{{ item.products }}</pre> -->
          <BrandLogo :brand="item.products[0].brand" size="lg" />
          <div class="order-history-card__content bi-flex-auto">
            <h3 class="bi-text-base-lg md:bi-font-normal bi-font-semibold bi-text-grey-darkest-ultra">
              {{ item.products[0].categories[0].name }}
            </h3>
            <div class="bi-flex bi-justify-between bi-flex-nowrap bi-flex-auto bi-items-center">
              <div>
                <!-- <p class="bi-font-medium bi-text-grey-default bi-text-xs">
                  {{ childItem.attributeOptions[0].name }}
                  {{ childItem.attributeOptions[0].attribute.name }}
                </p> -->
                <p class="bi-font-medium bi-text-grey-default bi-text-md">
                  Quantity : {{ getTotalQuantity(item.products) }}
                  {{ getUnit(item.products[0]) }}
                </p>
                <p class="bi-text-xs bi-text-grey-default bi-font-medium">
                  {{ $formatDate(item.created_at) }}
                </p>
              </div>

              <div class="bi-text-right add-to-cart">
                <!-- <product-counter
                  :key="'product-counter-' + childItem.id"
                  :id="childItem.id"
                  :price="childItem.price"
                  :quantityOfOrder="getProductQuantity(childItem.id)"
                /> -->

                <s-button
                  size="md"
                  icon="IconMdiPlus"
                  color="success"
                  shape="square"
                  @click.native="
                    goToProductListByBrand(
                      item.products[0].categories[0].id,
                      item.products[0].brand.id,
                      item.products[0].attributeOptions,
                      item.products
                    )
                  "
                  rounded
                />
              </div>
            </div>
          </div>
        </splide-slide>
      </template>
    </splide>
  </div>
</template>

<script>
import { getAll } from "@/api/order";
import cartMixin from "@/mixins/cartMixin";
import { mapGetters, mapActions } from "vuex";
import { getAttributeId, getAacBlocksTotalPriceWithGst } from "@/utils/common";
import creditLimitMixin from "@/mixins/creditLimit";
import messages from "@/data/message";

import { getDetails as getProductDetails } from "@/api/product.js";
export default {
  mixins: [cartMixin, creditLimitMixin],
  components: {
    BrandLogo: require("@/components/brandLogo/Component.vue").default,
    // ProductCounter: require("@/components/ProductCounter").default,
  },
  computed: {
    ...mapGetters({
      projectId: "user/getProjectId",
      getCategoryDetail: "getCategoryDetail",
    }),

    totalNoOfOrders() {
      let total = 0;
      if (this.orders.length) {
        total = this.orders.length;
      }

      return total;
    },
  },
  watch: {
    projectId() {
      this.getAllOrders();
    },
    categoryIdFromHomeScreen() {
      this.getAllOrders();
    },
  },
  data() {
    return {
      orders: [],
      perPage: 3,
      perTabletPage: 2,
      perMobilePage: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getAllOrders();
  },
  methods: {
    ...mapActions({
      setCategoryId: "setCategoryId",
    }),
    goToProductListByBrand(categoryId, brandId, attributeOptions, products) {
      if (this.creditLimitCrossed) {
        this.$shilp.notify({
          type: "info",
          title: messages.creditLimitExceeded,
        });
      } else {
        const isCategorySameInCart = this.checkIfCategoryIsSameInCart(categoryId);

        let isBrandSameInCart;
        const isBrandPresentInCart = this.checkIfBrandIsPresentInCart(brandId);

        if (isBrandPresentInCart) {
          isBrandSameInCart = this.checkIfBrandIsSameInCart(brandId);
        } else {
          this.setBrandIdInCart(brandId);
          this.setBrandNameInCart(products[0].brand.name);
          isBrandSameInCart = true;
        }

        if (isCategorySameInCart && isBrandSameInCart) {
          this.setCategoryId(categoryId);
          let attributeId;

          attributeId = getAttributeId(attributeOptions, "Grade");

          products.forEach((item) => {
            // Below product detail call is made to solve issue of #55 Reorder Bug getting updated price from backend with payment options.
            getProductDetails(item.id).then((res) => {
              const category = this.getCategoryDetail(categoryId);

              let modifiedPrice = 0;

              if (category.name == "AAC Blocks") {
                modifiedPrice = getAacBlocksTotalPriceWithGst(res.price, item.gst_percentage);
              } else {
                modifiedPrice = res.price;
              }
              this.addToCart({
                basePrice: item.total - item.gst_total_amount,
                gstAmount: item.gst_total_amount,
                product_id: item.id,
                quantity: item.quantity,
                price: modifiedPrice,
                total: item.total,
              });
            });

            this.setTotalNoOfProductsInCart(this.totalNoOfProductsInCart + 1);
          });

          this.setCategoryIdInCart(categoryId);
          this.setCategoryNameInCart(products[0].categories[0].name);
          this.setBrandIdInCart(brandId);
          this.setBrandNameInCart(products[0].brand.name);
          this.setLoadingOption(products[0].brand.loading);
          this.setUnloadingOption(products[0].brand.unloading);
          this.setFreightOption(products[0].brand.freight);

          let totalQuantity = 0;

          // For setting total Quantity
          for (let product of products) {
            if (product.unit == "Bag") totalQuantity = totalQuantity + (product.quantity * 50) / 1000;
            else totalQuantity = totalQuantity + product.quantity;
          }

          if (products[0].unit == "Bag") {
            this.setUnitInCart("MT");
            // this.setTotalQuantity(
            //   this.totalQuantity + (products[0].quantity * 50) / 1000
            // );
          } else {
            this.setUnitInCart(products[0].unit);
            // this.setTotalQuantity(this.totalQuantity + products[0].quantity);
          }

          this.setTotalQuantity(totalQuantity);

          this.$router.push({
            name: "product-list-by-brand",
            params: {
              categoryId: categoryId,
              id: brandId,
              attributeId: attributeId,
            },
          });
        } else {
          this.alertDifferentOrderCategoryNotAllowed();
          this.$root.$on("items-replaced-from-order-history", () => {
            // below emptyCart is called here to handle issue of cart not getting reset on replace button click in modal. Temporary fix.
            this.emptyCart();
            this.goToProductListByBrand(categoryId, brandId, attributeOptions, products);
          });
        }
      }
    },
    async getAllOrders() {
      this.isLoading = true;
      let res = await getAll({
        filter: {
          project_id: this.projectId ? this.projectId : null,
          category_id: this.categoryIdFromHomeScreen,
        },
        order_history: true,
        sort: { by: "id", order: "desc" },
        limit: 10,

        include: "products,products.brand,products.categories,products.attributeOptions.attribute,orderStatus,paymentStatus",
      });
      // order_products_unique: true,
      this.orders = res.data;
      this.$nextTick(() => {
        this.$refs.slider?.remount();
      });

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.order-slider {
  .splide__track {
    margin: 0px 60px;
  }
}

@media screen and (max-width: 768px) {
  .order-history-card {
    .add-to-cart {
      .button {
        width: 32px;
        height: 32px;
      }
    }

    img {
      width: 50px;
      height: 50px;
    }
  }
}
@media screen and (max-width: 425px) {
  .splide__arrow {
    display: none;
  }
  .order-slider {
    .splide__track {
      margin: 0;
    }
  }
}
</style>
