import { render, staticRenderFns } from "./AacBlocks.vue?vue&type=template&id=2148e763&scoped=true&"
import script from "./AacBlocks.vue?vue&type=script&lang=js&"
export * from "./AacBlocks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2148e763",
  null
  
)

export default component.exports