<template>
  <div class="contact-us-wrapper bi-h-full bl-lighter">
    <img src="@/assets/img/contact-us.svg" alt="" class="bi-max-w-full" />

    <div class="bi-m-4">
      <h1 class="bi-font-medium bi-text-semiLarge bi-text-grey-darkest-ultra">Chat with Us (+91) {{ mobileNo }}</h1>
      <p class="bi-text-base bi-font-normal bi-text-grey-default">
        Our team is available on WhatsApp. Drop a message and we will try to get back to you at earliest.
      </p>
    </div>

    <div class="bi-flex bi-justify-between bi-items-center bi-m-4">
      <div>
        <h1 class="bi-font-medium bi-text-semiLarge bi-text-grey-darkest-ultra">
          {{ supportPersonAssigned ? supportPersonAssigned.name : "AJAY C." }}
        </h1>
        <p class="bi-text-grey-default bi-text-xs bi-font-normal bi-capitalize">
          {{ supportPersonAssigned ? supportPersonAssigned.role[0] : "Dedicated Account Manager" }}
        </p>
      </div>
      <a :href="`https://web.whatsapp.com/send?phone=${mobileNo}&text=Hello BuilditIndia, We would like to get in touch with you.`" target="_blank">
        <s-button shape="square" color="success" icon="IconMdiWhatsapp" size="md" rounded />
      </a>
    </div>
    <hr class="b-lighter" />
    <div class="bi-flex bi-justify-between bi-items-center bi-m-4">
      <span class="bi-text-grey-darkest-ultra bi-text-base bi-font-normal">sales@builditindia.com </span>
      <a href="mailto:sales@builditindia.com" target="_blank">
        <s-button theme="muted" shape="square" size="md" icon="IconMdiEmailOutline" class="bi-w-10 bi-h-10" color="grey" />
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      countryCode: "+91",
    };
  },
  computed: {
    ...mapGetters({
      supportPersonAssigned: "user/supportPersonAssigned",
    }),
    mobileNo() {
      return this.supportPersonAssigned ? this.supportPersonAssigned.mobileNo : 9726425255;
    },
  },
};
</script>

<style scoped>
.contact-us-wrapper {
  width: 368px;
}
</style>
