<template>
  <div>
    <!-- :params="params" -->
    <div class="bi-ml-4">
      <h3 class="bi-text-primary-default">Sign In History</h3>
      <span class="bi-text-grey-default bi-mb-2">You can see your history here.</span>
    </div>
    <sp-list
      endpoint="rest:signInHistories"
      pagination-mode="infinite"
      v-infinite-scroll="loadMoreHistory"
      :attrs="columns"
      ref="list"
      :params="params"
      :actions="['']"
      infinite-scroll-distance="10"
    >
      <template #empty>
        <list-empty />
      </template>
      <div class="bi-overflow-x-scroll">
        <sp-list-table style="min-width: 700px">
          <template #user_id="{ item }">
            <span class="bi-text-md">{{ item.user_id }}</span>
          </template>
          <template #ip_address="{ item }">
            <span class="bi-text-md" v-if="item.ip_address">{{ item.ip_address }}</span>
            <data-not-found v-else />
          </template>
          <template #browser="{ item }">
            <span class="bi-text-md" v-if="item.browser">{{ item.browser }}</span>
            <data-not-found v-else />
          </template>
          <template #in_time="{ item }">
            <span class="bi-text-md" v-if="item.in_time">{{ $formatDate(item.in_time) }}</span>
            <data-not-found v-else />
          </template>
          <template #out_time="{ item }">
            <span class="bi-text-md" v-if="item.out_time">{{ $formatDate(item.out_time) }}</span>
            <data-not-found v-else />
          </template>
          <!-- <template #actions="{ item }">
          <s-button
            icon="IconMdiTrashCanOutline"
            shape="circle"
            color="danger"
            theme="muted"
            @click.native="deleteHistory(item.id)"
        /></template> -->
        </sp-list-table>
      </div>
    </sp-list>
  </div>
</template>

<script>
import { remove } from "@/api/signinHistory";

export default {
  data() {
    return {
      columns: [
        // { name: "user_id", label: "User ID" },
        { name: "in_time", label: "In Time" },
        { name: "browser", label: "Browser" },
        { name: "ip_address", label: "IP Address" },
        { name: "out_time", label: "Out Time" },
        // { name: "actions", label: "Action" },
      ],
    };
  },
  components: {
    DataNotFound: require("@/components/DataNotFound").default,
  },
  computed: {
    params() {
      return {
        limit: 25,
      };
    },
    tableCurrentPage() {
      return this.$refs.list.$data.localPage;
    },

    tableLastPage() {
      return this.$refs.list.$data?.response?.pagination?.last_page;
    },
  },
  methods: {
    loadMoreHistory() {
      // Code need to REFACTOR
      if (this.tableCurrentPage < this.tableLastPage) {
        return this.$refs.list.loadMore();
      }
    },
    async deleteHistory(id) {
      try {
        const selectedDelete = confirm("Are you sure you want to delete History?");
        if (selectedDelete) {
          await remove(id);
          this.$shilp.notify({
            title: "History Deleted Successfully.",
            type: "accent",
          });

          this.$refs.list.refresh();
        }
      } catch {
        this.$shilp.notify({
          title: "Something went wrong while deleting history. Try again.",
          type: "danger",
        });
      }
    },
  },
};
</script>
