<template>
  <div class="product-counter-container">
    <s-button
      v-if="showAddBtn"
      size="sm"
      color="accent"
      theme="muted"
      rounded
      class="add-item"
      :label="price ? 'Add' : 'RFP'"
      @click.native="addInitialQuantity"
    />
    <template v-else>
      <div class="bi-flex bi-items-center bi-gap-2 bi-flex-nowrap expanded-counter bi-justify-center">
        <!-- <s-button
          size="sm"
          color="success"
          class=""
          rounded
          icon="IconIcRoundMinus"
          @click.native="decrease"
        /> -->
        <!-- <div
          class="bi-border bi-rounded bi-border-grey-lightest-double-ultra bi-text-center bi-py-1 bi-h-8 bi-w-32"
        >
          {{ quantity }} {{ unit }}
        </div> -->

        <!-- <s-button
          size="sm"
          rounded
          color="success"
          class=""
          icon="IconIcRoundPlus"
          @click.native="increase"
        /> -->

        <s-field after="CBM" class="tmt-input bi-w-36">
          <s-textbox type="number" v-mask="numberMask" v-model.number="quantity" @focus.native="$event.target.select()" @input="setQuantity" />
        </s-field>

        <s-button size="sm" theme="muted" color="danger" label="Remove" rounded v-if="!showAddBtn" @click.native="remove" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import cartMixin from "@/mixins/cartMixin";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

export default {
  props: [
    "id",
    "price",
    "quantityOfOrder",
    "categoryId",
    "unit",
    "brandId",
    "brandName",
    "loading",
    "unloading",
    "freight",
    "gstPercentage",
    "isGstIncluded",
    "item",
  ],
  mixins: [cartMixin],
  data() {
    return {
      quantity: 0,
      showAddBtn: true,
      numberMask: createNumberMask({
        allowDecimal: false,
        includeThousandsSeparator: false,
        prefix: "",
      }),
    };
  },
  computed: {
    gstAmount() {
      // GST EXcluded , therefore adding up
      // REFACTOR below conversion of 2 decimal places and casting to Number.
      // return Number((this.totalAmount * (this.gstPercentage / 100)).toFixed(2));

      return (this.totalAmount / (100 + this.gstPercentage)) * this.gstPercentage;
    },
    basePrice() {
      // return Number((this.price - this.gstAmount).toFixed(2));
      return this.totalAmount - this.gstAmount;
    },

    totalAmount() {
      if (this.getExtraTimeCharges) {
        return (this.price + this.addGstWithExtraTimeCharges(this.getExtraTimeCharges)) * this.quantity;
      } else return this.price * this.quantity;
    },
  },

  watch: {
    basePrice() {
      let product = this.findProductInCart(this.id);
      product.basePrice = this.basePrice;
      product.gstAmount = this.gstAmount;
      product.gstPercentage = this.gstPercentage;
      product.total = this.totalAmount;
      this.updateCart(this.cartProducts);
    },
  },
  created() {
    // below event registration is to solve issue of counter state not updating after truck is cleared from button. therfore manually resetting.
    this.$root.$on("truck-got-empty", () => {
      this.quantity = 0;
      this.showAddBtn = true;
    });

    this.$root.$on("truck-got-empty-and-items-replaced", this.replaceProduct);
  },

  beforeDestroy() {
    this.$root.$off("truck-got-empty-and-items-replaced", this.replaceProduct);
  },

  mounted() {
    if (this.quantityOfOrder) {
      this.quantity = this.quantityOfOrder;
      this.showAddBtn = false;
    }
  },
  methods: {
    ...mapActions(["addToCart", "updateCart"]),
    addGstWithExtraTimeCharges(amount) {
      return amount + amount * (this.gstPercentage / 100);
    },
    replaceProduct({ id }) {
      if (this.categoryId == 3 && this.id == id) {
        this.addInitialQuantity();
      }
    },
    remove() {
      this.removeProductFromCart();
      this.showAddBtn = true;
      if (this.cartProducts && !this.cartProducts.length) {
        this.emptyCart();
      }
    },
    addInitialQuantity() {
      const isCategorySameInCart = this.checkIfCategoryIsSameInCart(this.categoryId);

      let isBrandSameInCart;
      const isBrandPresentInCart = this.checkIfBrandIsPresentInCart(this.brandId);

      if (isBrandPresentInCart) {
        isBrandSameInCart = this.checkIfBrandIsSameInCart(this.brandId);
      } else {
        this.setBrandIdInCart(this.brandId);
        this.setBrandNameInCart(this.brandName);
        this.setUnitInCart(this.unit);
        isBrandSameInCart = true;
      }

      if (isCategorySameInCart && isBrandSameInCart) {
        this.setLoadingOption(this.loading);
        this.setUnloadingOption(this.unloading);
        this.setFreightOption(this.freight);
        this.setCategoryNameInCart(this.categoryNameFromHomeScreen);
        this.quantity = 1;
        this.addToCart({
          // totalGstAmount: this.totalGstAmount,
          // totalBasePrice: this.totalBasePrice,
          price: this.price,
          product_id: this.id,
          quantity: 1,
          total: this.totalAmount,
          basePrice: this.basePrice,
          gstAmount: this.gstAmount,
          gstPercentage: this.gstPercentage,
        });

        this.setGstPercentage(this.gstPercentage);
        this.setGstIncluded(this.isGstIncluded);

        this.addProductCountInTotalQuantityOfCart();

        this.setTotalQuantity(this.totalQuantity + 1);
        this.showAddBtn = false;
      } else {
        this.alertDifferentOrderCategoryNotAllowed(this.id);
      }
    },

    // decrease() {
    //   this.quantity--;

    //   if (this.quantity == 0) this.removeProductCountFromTotalQuantityOfCart();

    //   const isProductPresent = this.checkIfProductIsPresentInCart();
    //   if (isProductPresent) {
    //     let product = this.findProductInCart(this.id);
    //     product.quantity--;

    //     if (product.quantity == 0) {
    //       let arr = this.cartProducts.filter((item) => {
    //         return item.product_id != product.product_id;
    //       });

    //       this.updateCart(arr);
    //     } else {
    //       let total = product.quantity * this.price;
    //       product.total = total;
    //       this.updateCart(this.cartProducts);
    //     }
    //   }

    //   this.setTotalQuantity(this.totalQuantity - 1);
    // },

    setQuantity() {
      if (!this.quantity && this.quantity <= 0) {
        this.removeProductFromCart();
        return;
      } else {
        const isCategorySameInCart = this.checkIfCategoryIsSameInCart(this.categoryId);

        if (isCategorySameInCart) {
          const isProductPresent = this.checkIfProductIsPresentInCart();
          if (isProductPresent) {
            let product = this.findProductInCart(this.id);

            let oldQuantity = product.quantity;
            product.quantity = this.quantity;
            // let total = product.quantity * this.price;

            // let total = this.totalAmount;
            // product.totalGstAmount = this.totalGstAmount;
            // product.totalBasePrice = this.totalBasePrice;
            product.total = this.totalAmount;
            product.basePrice = this.basePrice;
            product.gstAmount = this.gstAmount;
            product.gstPercentage = this.gstPercentage;

            this.setGstPercentage(this.gstPercentage);
            this.setGstIncluded(this.isGstIncluded);

            this.updateCart(this.cartProducts);
            this.setTotalQuantity(Number((this.totalQuantity - oldQuantity + this.quantity).toFixed(2)));
          } else this.addInitialQuantity();
        } else {
          this.alertDifferentOrderCategoryNotAllowed(this.id);
        }
      }
    },

    // increase() {
    //   const isCategorySameInCart = this.checkIfCategoryIsSameInCart(
    //     this.categoryId
    //   );

    //   if (isCategorySameInCart) {
    //     this.setCategoryNameInCart(this.categoryNameFromHomeScreen);
    //     this.quantity++;
    //     if (this.quantity == 1) {
    //       this.addProductCountInTotalQuantityOfCart();
    //     }
    //     const isProductPresent = this.checkIfProductIsPresentInCart();
    //     if (isProductPresent) {
    //       let product = this.findProductInCart(this.id);
    //       product.quantity++;
    //       let total = product.quantity * this.price;
    //       product.total = total;
    //       this.updateCart(this.cartProducts);
    //     } else {
    //       this.addToCart({
    //         price: this.price,
    //         product_id: this.id,
    //         quantity: this.quantity,
    //         total: this.price,
    //       });
    //     }
    //   } else {
    //     this.alertDifferentOrderCategoryNotAllowed();
    //   }

    //   this.setTotalQuantity(this.totalQuantity + 1);
    // },
  },
};
</script>

<style></style>
