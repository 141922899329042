<template>
  <div class="layout__wrap bi-block bi-w-full md:bi-flex md:bi-justify-left md:bi-items-center">
    <div class="md:bi-w-full">
      <div class="bi-mb-2 bi-mb-6">
        <div class="bi-flex bi-items-center bi-justify-between">
          <h3 class="bi-text-primary-default">Inquiry List</h3>
          <s-button
            label="Create Inquiry"
            theme="solid"
            color="secondary"
            @click.native="
              {
                $router.push({ name: 'inquiry-create', params: { id: '+' } });
              }
            "
          />
        </div>
        <div class="bi-text-grey-default"><p>Let us know your choices and requirements.</p></div>
      </div>
      <sp-list
        class="bi-overflow-hidden"
        endpoint="rest:inquiries"
        :params="{ include: 'inquiryStatus' }"
        :pagination="true"
        :perPage="10"
        ref="partnersList"
        :attrs="columns"
        :actions="['']"
        sortBy="created_at"
        sortOrder="desc"
      >
        <template #empty>
          <list-empty />
        </template>
        <div class="bi-overflow-x-scroll">
          <sp-list-table class="p-0" @rowClick="goToDetail" style="min-width: 500px">
            <template #status="{ item }">
              <Tag :label="item.inquiryStatus.name" />
            </template>
          </sp-list-table>
        </div>
      </sp-list>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    ListEmpty: require("@/components/ListEmpty").default,
  },

  data() {
    return {
      inquiryListData: null,
      columns: [
        {
          name: "created_at",
          label: "Date & Time",
          sortable: true,
          value: (item) => {
            return this.$formatDate(item.created_at);
          },
        },
        "category",
        "brand",
        "grade",
        {
          name: "size_packing",
          label: "Size/Packing",
        },
        "qty",
        {
          name: "status",
          label: "Status",
        },
      ],
    };
  },

  methods: {
    goToDetail(row) {
      this.$router.push({ name: "inquiry-view", params: { id: row.id } });
    },
  },
};
</script>

<style lang="scss" scoped></style>
