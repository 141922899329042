<template>
  <div class="layout__wrap">
    <page-header class="bi-mb-6" title="Credits"> </page-header>
    <div class="bi-flex md:bi-gap-8 bi-gap-4">
      <s-field label="Total Credit">
        <!-- <s-textbox :value="totalCredit ? formatCredit(totalCredit) : 'N/A'" disabled /> -->
        <div class="bi-text-grey-default">{{ totalCredit ? formatCredit(totalCredit) : "N/A" }}</div>
      </s-field>

      <s-field label="Available Credit">
        <!-- <s-textbox :value="availableCredit ? formatCredit(availableCredit) : 'N/A'" disabled /> -->
        <div class="bi-text-grey-default">{{ availableCredit ? formatCredit(availableCredit) : "N/A" }}</div>
      </s-field>

      <s-field label="Utilized Credit">
        <!-- <s-textbox :value="utilizedCredit ? formatCredit(utilizedCredit) : 'N/A'" disabled /> -->
        <div class="bi-text-grey-default">{{ utilizedCredit ? formatCredit(utilizedCredit) : "N/A" }}</div>
      </s-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    PageHeader: require("@/components/PageHeader").default,
  },
  computed: {
    ...mapGetters({
      availableCredit: "user/availableCredit",
      totalCredit: "user/totalCredit",
      utilizedCredit: "user/utilizedCredit",
    }),
  },
  methods: {
    formatCredit(credits) {
      if (credits) {
        return this.$formatPrice(credits);
      } else return "₹ " + 0;
    },
  },
};
</script>

<style lang="scss" scoped></style>
