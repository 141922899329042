<template>
  <div class="bi-rounded-lg bi-shadow-lg bi-p-5">
    <!-- Label -->
    <div class="bi-text-3xl bi-font-bold bi-mb-2 bi-flex bi-justify-between bi-items-center">
      <div>
        Order ID: <span class="bi-text-secondary-default">{{ order_no || "#" + id }}</span>
      </div>
      <h4>{{ $formatDate(dateTime) }}</h4>
    </div>

    <div class="bi-grid bi-gap-5 bi-grid-cols-4">
      <div class="bi-flex bi-items-center">
        <!-- Category -->
        <BrandLogo :brand="brand" size="sm" class="bi-mr-2" />
        <div>
          <h4 class="bi-font-bold">{{ brand.name }}</h4>
          <p class="bi-font-semibold bi-text-grey-default">{{ category }}</p>
        </div>
      </div>

      <div>
        <!-- expectedDeliveryDate -->
        <h4 class="bi-text-grey-default bi-font-normal">Expected Delivery Date</h4>
        <h4 class="bi-font-bold">{{ moment(expectedDeliveryDate * 1000).format("MMM D YYYY") }}</h4>
      </div>

      <div>
        <!-- Payment Status -->
        <h4 class="bi-text-grey-default bi-font-normal">Payment Status</h4>
        <payment-status-tag :label="paymentStatus.display_name" :name="paymentStatus.name" />
      </div>

      <div>
        <!--  Payment terms-->
        <h4 class="bi-text-grey-default bi-font-normal">Payment Terms</h4>
        <h4 class="bi-font-bold">{{ paymentTerms }}</h4>
      </div>
    </div>

    <div class="bi-grid bi-grid-cols-4 bi-mt-4">
      <div>
        <!-- Loading -->
        <h4 class="bi-text-grey-default bi-font-normal">Loading</h4>
        <Tag
          :label="totalPrice ? (isLoadingIncluded ? 'Included' : 'Excluded') : 'At Actual'"
          :color="isLoadingIncluded ? 'green' : 'red'"
          width="50px"
        />
      </div>

      <div>
        <!-- Unloading -->
        <h4 class="bi-text-grey-default bi-font-normal">Unloading</h4>
        <Tag
          :label="totalPrice ? (isUnloadingIncluded ? 'Included' : 'Excluded') : 'At Actual'"
          :color="isUnloadingIncluded ? 'green' : 'red'"
          width="50px"
        />
      </div>

      <div>
        <!--Freight -->
        <h4 class="bi-text-grey-default bi-font-normal">Freight</h4>
        <Tag
          :label="totalPrice ? (isFreightIncluded ? 'Included' : 'Excluded') : 'At Actual'"
          :color="isFreightIncluded ? 'green' : 'red'"
          width="50px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["dateTime", "expectedDeliveryDate", "paymentTerms", "category", "brand", "paymentStatus", "id", "order_no", "totalPrice", "loading", "unloading","freight"],
  components: {
    BrandLogo: require("@/components/brandLogo/Component.vue").default,
    Tag: require("@/components/Tag").default,
    PaymentStatusTag: require("@/components/order/PaymentStatusTag").default,
  },
  computed: {
    isLoadingIncluded() {
      if (this.loading) {
        return false;
      } else {
        return true;
      }
    },
    isUnloadingIncluded() {
      if (this.unloading) {
        return false;
      } else {
        return true;
      }
    },
    isFreightIncluded() {
      if (this.freight) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    moment,
  },
};
</script>

<style lang="scss" scoped></style>
