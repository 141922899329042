import graphql from "graphql.js";
// import fragments from "@admin/graph/fragments";

import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  gql,
} from "@apollo/client/core";
import { createUploadLink } from "apollo-upload-client";

var graph;
var apollo;
var apolloUpload;
var graphPublic = graphPublic = graphql(process.env.VUE_APP_GRAPHQL_OPEN, {
  method: "POST", // POST by default.
});

const init = (token) => {
  token = `Bearer ${token}`;
  const endpoint = process.env.VUE_APP_GRAPHQL;

  graphPublic = graphql(process.env.VUE_APP_GRAPHQL_OPEN, {
    method: "POST", // POST by default.
    headers: {
      Authorization: token
    },
  });

  graph = graphql(endpoint, {
    method: "POST", // POST by default.
    headers: {
      Authorization: token,
      // "web-version": version,
    },
    // fragments,
  });

  const httpLinkConfig = {
    uri: endpoint,
    headers: {
      Authorization: token,
    },
  };

  apollo = new ApolloClient({
    link: createHttpLink(httpLinkConfig),
    cache: new InMemoryCache(),
  });

  apolloUpload = new ApolloClient({
    link: createUploadLink(httpLinkConfig),
    cache: new InMemoryCache(),
  });
};

export { graph, init, graphPublic, apollo, apolloUpload, gql };
