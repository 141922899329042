<template>
  <div class="bi-w-screen bi-h-screen main-block">
    <div v-if="loading" class="bi-flex bi-flex-col bi-h-full bi-justify-center bi-items-center">
      <img src="@/assets/img/logo-blue-full.svg" alt="" class="bi-h-32"/>
      <loader />
    </div>
    <s-layout
      v-else
      push-top
      :left-size="compact ? '73px' : '200px'"
      full-height
      :top-size="topSize"
      ref="mainContainer"
      :top-classlist="['col-start-1']"
      :left-classlist="['br-lightest']"
      :right-classlist="['bl-lightest', 'bi-p-0']"
    >
      <template #top>
        <CompanyBanner v-if="isAdminLoggedIn" class="bi-sticky bi-top-0"/>
        <the-header class="bi-sticky bi-top-0 bi-z-50">
          <template #logo>
            <div class="company-logo">
              <router-link :to="{ name: 'home' }">
                <img src="@/assets/img/logo-light-full.svg" style="height:'60px'" />
              </router-link>
            </div>
          </template>

          <template #mobile-menu>
            <mobile-menu />
          </template>
          <template #default>
            <DesktopMenu />
          </template>

          <template #right-side>
            <RightSideMenu />
          </template>
        </the-header>

        <!-- Client UI Update  -->
        <!-- <portal-target name="project-dropdown" slim /> -->

        <div class="bi-flex bi-flex-nowrap bi-justify-between bi-items-center bi-px-2 bb-lightest bi-sticky bi-top-0 bi-bg-white bi-z-30">
          <div class="bi-flex bi-items-center">
            <portal-target name="project-dropdown" slim />
            <portal-target name="header-actions-leftside" />
            <s-breadcrumbs class="bi-flex-auto" />
          </div>
          <portal-target name="header-actions" slim />
        </div>
      </template>

      <template #default>
        <router-view class="container-min-height bi-overflow-y-auto" :class="{'bi-pt-24 lg:bi-pt-0': isAdminLoggedIn}"/>
      </template>
    </s-layout>
    <footer
      class="bi-bg-primary-default bi-w-full bi-text-white bi-flex-nowrap bi-p-3 md:bi-px-6 bi-gap-4 md:bi-gap-10 bi-block bi-text-center"
      :class="{ 'bi-bottom-0 bi-fixed': $route.name == 'product-list-by-brand' || $route.name == 'truck' }"
    >
      © BuilditIndia | All Rights Reserved, {{ new Date().getFullYear() }}.
    </footer>
  </div>
</template>

<script>
// import { me } from "@/api/user.js";
import { init as initGraph } from "@/plugins/graphql";
import { getToken } from "@/utils/common.js";
import { setToken } from "@/plugins/axios";
import { mapActions, mapGetters } from "vuex";
import kycMixin from "@/mixins/kycMixin";
import kycStatusOptions from "@/data/kyc";
import { get } from "@/api/category";

import { getAdminId } from "@/utils/adminOrder.js";
import { eventBus } from '@/plugins/event-bus.js';

export default {
  kycStatusOptions,
  mixins: [kycMixin],
  data() {
    return {
      loading: true,
      collapse: false,
      compact: false,
      projectId: null,
    };
  },
  components: {
    TheHeader: require("@/components/header/TheHeader").default,
    Loader: require("@/components/Loader").default,

    MobileMenu: require("@/components/header/MobileMenu.vue").default,
    DesktopMenu: require("@/components/header/DesktopMenu").default,
    RightSideMenu: require("@/components/header/RightSideMenu").default,
    CompanyBanner: require("@/components/account/CompanyBanner").default,

    // AccountAlert: require("@/components/AccountAlert").default,
  },
  created() {
    // this.getUser();
    const token = getToken();
    if (token) {
      setToken(token);
      initGraph(token);
      eventBus.$on('refreshHydration', (projectId)=>{
        this.getCategories({ include: "attribute.attributeOption,paymentTerms",project_id: projectId, filter:{status:'active'} });
      });
      this.startHydration();
    } else this.$router.push({ name: "login" });
  },

  computed: {
    ...mapGetters({
      hydrated: "hydrated",
      status: "user/status",
      user: "user/get",
    }),

    isAdminLoggedIn() {
      if (getAdminId()) return true;
      else return false;
    },

    topSize() {
      // Below logic is written to solve UI Issue when kyc is incomplete , pending .... there was too much empty space on top. project dropdown is not shown in this situation.

      const adminId = getAdminId();

      let result = null;
      if (adminId) result = "190px";
      else result = "136px";
      if (this.kycStatus == "approve") return result;
      else {
        if (this.$route.name == "home-content" || this.$route.name == "static-content") return "auto";
        else return result;
      }
    },
    // having dynamic topsize in order to handle dynamic height of top header , sometimes project dropdown is present  sometimes not

    // Code commented for client update
    // topSize() {
    //   if (this.allowedRoutesForUpdatingHeaderHeight.includes(this.$route.name))
    //     return "136px";
    //   return "136px";
    // },
  },

  methods: {
    ...mapActions(["hydrate", "setCategories"]),
    async startHydration() {
      this.loading = true;
      try {
        await this.hydrateUserWithCompanyType();
        const defaultProject = this.user.companies[0].projects.find(project => project.isDefault === true);
        if (defaultProject) {
          this.projectId = defaultProject.id;
        }
        this.getCategories({ include: "attribute.attributeOption,paymentTerms",project_id: this.projectId, filter:{status:'active'} });
      } catch (error) {
        localStorage.removeItem("bi-token");
        localStorage.removeItem("bi-last-path");
        this.$router.replace({ name: "login" });
      } finally {
        this.loading = false;
      }
    },

    async getCategories(params) {
      let res = await get(params);
      if (res.data && res.data.length) {
        // Mapping with label and value.
        res.data.forEach((category) => {
          if (category.paymentTerms) category.paymentTerms = this.mapPaymenOptions(category.paymentTerms);
        });
        res = res.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
            ...item,
          };
        });
        this.setCategories(res);
      }
    },

    mapPaymenOptions(options) {
      return options.map((item) => {
        return {
          label: item.description,
          value: item.id,
          ...item,
        };
      });
    },

    routeRedirect(lastPath) {
      // This method is for navigating user to last visited Page before CLosing the Store.
      //  Example if user had visited Orders Details Page and then closed. After Some time Revisiting The Portal should navigate the user to that last visited section.
      if (this.$route.matched && this.$route.matched.length && lastPath) {
        for (let route of this.$route.matched) {
          if (route.path == lastPath) {
            return;
          }
        }
        this.$router.push({ path: lastPath });
      }
    },
  },
};
</script>

<style lang="scss">
.col-start-1 {
  grid-column-start: 1 !important;
}
.loading {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    width: 100px;
    height: 100px;
    border: 1px solid --color(grey, lighter);
    border-radius: --radius(4);
    padding: --space(3);
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}
.company-logo {
  width: 152px;
  height: auto;
}
.container-min-height {
  min-height: calc(100vh - 181px);
}

@media only screen and (max-width: 767px) {
  .company-logo {
    margin-left: 20px;
  }
  .main-block .layout--auth {
    display: block;
  }
  // .wh-layout__nav .nav__item--exact-active,
  // .wh-layout__nav .nav__item--exact-active a {
  //   border-bi-bottom-width: 2px;
  //   border-right-width: 0px;
  // }
  .main-block .sub-layout-sidebar .categories-block {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 15px;
    background-color: #f1f1f1;
    overflow: scroll;
    // position: fixed;
  }
  .main-block .sub-layout-sidebar ul {
    position: static !important;
  }
  .main-block .layout__content {
    margin-top: 0px;
  }
}
</style>
