<template>
  <div class="sign">
    <div class="sign__left">
      <img src="@/assets/img/logo-light-full.svg" width="260px" height="250px" alt />
    </div>
    <div class="sign__right">
      <div class="sign__box">
        <!-- <a class="bi-flex bi-justify-center bi-mb-3">
          <h2>Welcome </h2>
          <h2>Back!</h2>
        </a> -->
        <slot />
      </div>
      <Version class="bi-mt-2 bi-bg-none bi-text-right" />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Version: require("@/components/Version").default,
  },
};
</script>
<style lang="scss"></style>
