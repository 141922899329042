<template>
  <div class="bi-p-2 bi-flex-grow" :class="`bi-bg-${bgColor}`">
    <p class="bi-text-md" :class="`bi-text-${color}`">
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      message: "",
      bgColor: "",
      color: "white",
    };
  },
  mounted() {
    this.setMessage();
  },
  methods: {
    // FIXME KYC Flow When Editing KYC, go to home screen we get incomplete and then on click id is + instead of existing id details
    setMessage() {
      switch (this.status) {
        case "inreview":
          this.message = "Your KYC Application is in Review.";
          this.bgColor = "primary-default";
          break;
        case "reject":
          this.message = "Your KYC is Rejected. Please contact us for reasons.";
          this.color = "danger-default";
          break;
        case "incomplete":
          this.message = "Your KYC is Incomplete. Please finish all the kyc steps.";
          // this.bgColor = "transparent";
          this.bgColor = "secondary-default";
          break;
        default:
          this.message = "You need to complete KYC to view price or purchase our products.";
          this.bgColor = "transparent";
          this.color = "black";
      }
    },
  },
};
</script>

<style></style>
