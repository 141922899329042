<template>
  <div class="bi-rounded-md bi-grid bi-text-grey-default bi-items-center bi-gap-2" style="grid-template-columns: auto 30px auto">
    <!-- Base Price  -->
    <h4 class="bi-text-grey-default bi-font-normal">Base Price</h4>
    <span>:</span>
    <h4 class="bi-font-bold">{{ $formatPrice(basePrice) }}</h4>

    <!-- GST Section -->
    <h4 class="bi-text-grey-default bi-font-normal">GST ({{ gstPercentage }}%)</h4>
    <span>:</span>
    <h4 class="bi-font-bold">{{ $formatPrice(gstAmount) }}</h4>
  </div>
</template>

<script>
// REFACTOR remove this component and use General PriceBreakUpComponent.
export default {
  props: ["gstAmount", "basePrice", "gstPercentage"],
};
</script>

<style lang="scss" scoped></style>
