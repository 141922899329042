<template>
  <div class="bi-flex bi-flex-wrap md:bi-flex-nowrap bi-w-full">
    <!-- Below extra div is kept to solve issue of Button rendering in grid layout Right to form  -->
    <div class="bi-w-full md:bi-w-4/12">
      <item-form
        :item="false"
        :save="save"
        :footer="true"
        :redirect="false"
        :form-state.sync="formState"
        class="bi-p-6 bi-w-full"
        :gap="2"
        :fields="fields"
        :footerClassList="['bi-mt-2']"
        ref="item"
        :notify="false"
      >
        <template #default="{ form }">
          <!---Mobile Number --->

          <CustomMobileInput v-model="form.mobileNo" />
          <!--- Incharge Name --->
          <s-field-validate rules="required" name="Name" v-shilp-loader="isNameLoading">
            <TextBoxCapitalize placeholder="Name" v-model="form.name" :disabled="isNameDisabled" />
          </s-field-validate>
          <!--- Incharge Designation --->
          <s-field-validate name="Designation" rules="required">
            <CustomDropDown
              :key="dropdownKey"
              v-if="designationOptions.length"
              :options="designationOptions"
              v-model="form.designationId"
              placeholder="Select Designation"
            />
          </s-field-validate>
        </template>

        <template #footer="{ saving }">
          <div class="bi-flex bi-items-center">
            <s-button
              v-if="id == '+' || !isInchargeEditing"
              label="ADD"
              color="success"
              :loader="newInchargeAddedLoader"
              @click.native="addIncharge"
            ></s-button>
            <s-button v-else label="EDIT" color="success" type="submit" :loader="saving"></s-button>
          </div>
          <!-- <s-button
              size="md"
              :label="id == '+' ? 'Add New Project Incharge' : 'Edit Project Incharge'"
              :loader="saving"
              type="submit"
              color="success"
            /> -->
        </template>
      </item-form>
    </div>

    <!-- <s-table class="project-incharge-table" border="y" :size="4" edge v-if="inchargeList && inchargeList.length">
      <tr class="bi-font-bold">
        <td>Name</td>
        <td>Designation</td>
        <td>Mobile Number</td>
      </tr>
      <tr
        class="bi-cursor-pointer"
        @click="setInchargeDetails(item)"
        v-for="(item, index) in inchargeList"
        :key="`item-${index}`"
      >
        <td>
          {{ item.name }}
        </td>

        <td>
          {{ item.designation[0].displayName }}
        </td>
        <td>
          {{ item.mobileNo }}
        </td>
      </tr>
    </s-table> -->

    <div :class="{ 'lg:bi-border-l bi-border-grey-lightest-ultra bi-w-full': inchargeList.length }" v-if="!isLoading">
      <div v-if="projectDetails" class="bi-flex bi-justify-between bi-p-4 bi-border-b bi-border-grey-lightest-ultra">
        <p>Project Name: {{ projectDetails.name }}</p>
        <p>Address: {{ projectDetails.addressLineOne  }} {{ projectDetails.landmark }}, {{ projectDetails.city }}</p>
      </div>
      <sp-list
        style="max-height: 500px; overflow: auto"
        :data="inchargeList"
        :attrs="columns"
        :actions="['']"
        :class="{ clickable: id !== '+' }"
        class="table-width bi-p-6 bi-w-full"
        v-if="inchargeList && inchargeList.length"
      >
        <hr class="lg:bi-mb-0 bi-mb-4 lg:bi-hidden bi-border-grey-lightest-ultra" />
        <div class="bi-text-primary-default bi-text-lg bi-font-semibold bi-pl-4">Incharge Details</div>

        <sp-list-table @rowClick="setInchargeDetails">
          <template #name="{ item }">
            <span class="bi-text-md" :class="{ 'bi-text-primary-default bi-font-bold': id !== '+' }">{{ item.name }}</span>
          </template>
          <template #designationId="{ item }">
            <span class="bi-text-md">{{ item.designation[0].displayName }}</span>
          </template>

          <template #mobileNo="{ item }">
            <span class="bi-text-md">{{ item.mobileNo }}</span>
          </template>

          <template #actions="{ item }">
            <div class="bi-text-center">
              <s-button icon="IconIcSharpEdit" theme="muted" shape="circle" color="secondary" @click.native="setInchargeDetails(item)" />
            </div>
          </template>
        </sp-list-table>
      </sp-list>

      <template v-else>
        <TableEmpty class="table-width bi-p-6 lg:bi-border-l bi-border-grey-lightest-ultra bi-w-full" :columns="columns" message="No Incharge Found." />
      </template>
      <s-button class="bi-ml-6 bi-mt-5" size="lg" color="secondary" @click.native="checkInchargeList" label="Submit" />
    </div>
    <FormShimmer v-if="isLoading" class="table-width" />
  </div>
</template>

<script>
import upsertIncharge from "@/graph/incharge/upsert.gql";
import getIncharges from "@/graph/incharge/collection.gql";
import get from "@/graph/incharge/get.gql";
import { cloneDeep } from "lodash-es";
import { mapGetters } from "vuex";
import inchargeByMobileNo from "@/graph/projects/inchargeByMobileNo.gql";
import getOptions from "@/graph/designation/collection.gql";
import { alertApiError } from "@/utils/common.js";
import getProject from "@/graph/projects/get.gql";

export default {
  props: ["id", "projectId"],
  components: {
    TextBoxCapitalize: require("@/components/TextBoxCapitalize").default,
    CustomDropDown: require("@/components/CustomDropDown").default,
    CustomMobileInput: require("@/components/CustomMobileInput").default,
    TableEmpty: require("@/components/TableEmpty").default,
    FormShimmer: require("@/components/FormShimmer").default,
  },
  data() {
    return {
      dropdownKey: 1,
      fields: ["name", "mobileNo", "designationId"],
      columns: [
        {
          name: "name",
          label: "Name",
        },
        { name: "designationId", label: "Designation" },
        { name: "mobileNo", label: "Mobile Number" },
        {
          name: "actions",
          label: "Actions",
          visible: this.id == "+" ? false : true,
        },
      ],
      isInchargeEditing: false,
      newInchargeAddedLoader: false,
      formState: {},
      inchargeId: null,
      inchargeList: [],
      isLoading: false,
      designationOptions: [],
      isNameDisabled: false,
      isNameLoading: false,
      projectDetails: null
    };
  },
  created() {
    if (!this.projectId) this.$router.push({ name: "projects" });
    this.getDesignationOptions();
    if (this.id != "+") {
      this.inchargeId = this.id;
      this.getAllProjectIncharges();
    }
    this.getProjectDetails();
  },
  watch: {
    "formState.mobileNo": {
      immediate: false,
      handler(newValue) {
        if (newValue && newValue.length == 10) {
          this.isNameLoading = true;
          this.$graph(inchargeByMobileNo, { mobileNo: newValue })
            .then((res) => {
              if (res.inchargeByMobileNo) {
                this.formState.name = res.inchargeByMobileNo.name;
                this.isNameDisabled = true;
              } else {
                this.formState.name = "";
                this.isNameDisabled = false;
              }
            })
            .catch()
            .finally(() => {
              this.isNameLoading = false;
            });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      user: "user/get",
    }),
  },
  methods: {
    getProjectDetails() {
      this.$graph(getProject, {
        id: parseInt(this.projectId),
      }).then((res) => {
        this.projectDetails =  res.project;

      });
    },
    setInchargeDetails(incharge) {
      if (this.id == "+") return;
      this.isInchargeEditing = true;
      this.formState.name = incharge.name;
      this.formState.mobileNo = incharge.mobileNo;
      this.formState.designationId = incharge.designation[0].id;
      this.inchargeId = incharge.id;
      this.dropdownKey++;

      this.$refs.item.$el.scrollIntoView({ behavior: "smooth" });
    },
    async addIncharge() {
      const isValid = await this.$refs.item.validateForm();
      if (!isValid) return;
      try {
        this.newInchargeAddedLoader = true;
        let form = cloneDeep(this.formState);
        form["companyId"] = this.user.companies[0].id;
        form["projectId"] = this.projectId;

        await this.uploadInchargeData(form);
      } finally {
        this.newInchargeAddedLoader = false;
      }
    },

    async uploadInchargeData(formData, id = null) {
      return new Promise((resolve, reject) => {
        this.$graph(upsertIncharge, { id, input: formData })
          .then(() => {
            this.getAllProjectIncharges();

            this.resetValidation();
            this.dropdownKey++;
            resolve(true);
          })
          .catch((error) => {
            console.log("error ", error);
            alertApiError(error, this);
            reject(true);
          });
      });
    },

    resetValidation() {
      this.$refs.item.reset();
    },

    checkInchargeList() {
      if (!(this.inchargeList && this.inchargeList.length)) {
        this.$shilp.notify({
          title: "Please add Incharge before Submitting.",
          type: "danger",
        });
      } else {
        this.$router.push({ name: "projects" });
      }
    },
    async getAllProjectIncharges() {
      this.isLoading = true;
      let res = await this.$graph(getIncharges, {
        filters: { projectId: this.projectId },
      });
      this.inchargeList = res.incharges.data;
      this.isLoading = false;
    },
    async getDesignationOptions() {
      let res = await this.$graph(getOptions);
      this.designationOptions = res.designation.data.map((item) => {
        return {
          name: item.displayName,
          value: item.id,
        };
      });
    },
    async save() {
      let form = cloneDeep(this.formState);
      form["companyId"] = this.user.companies[0].id;
      form["projectId"] = this.projectId;
      if (this.id != "+") {
        if (!this.inchargeId) {
          this.$shilp.notify({
            title: "Please select incharge before submitting.",
            type: "danger",
          });

          return;
        }
      }
      await this.uploadInchargeData(form, parseInt(this.inchargeId));

      this.isInchargeEditing = false;
      // return this.$graph(upsertIncharge, {
      //   id: this.inchargeId,
      //   input: form,
      // }).then(() => {
      //   this.getAllProjectIncharges();
      // });
    },
    async get() {
      return this.$graph(get, {
        id: this.inchargeId,
      })
        .then((res) => {
          if (res.incharge.length && res.incharge.designation) res.incharge["designationId"] = res.incharge.designation[0].id;
          return res.incharge;
        })
        .catch()
        .finally(() => {});
    },
  },
};
</script>

<style lang="scss">
.table-width {
  @apply lg:bi-w-2/5;
}
</style>
