<template>
  <span>
    {{ $formatPrice(price) }}<span v-if="unit">/ {{ unit }} </span></span
  >
</template>

<script>
export default {
  props: {
    price: {
      type: [String, Number],
      required: true,
    },
    unit: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
